import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined'

import { SitePortalPermissions } from '@/features/auth/types'
import ROUTES from '@/router/routes'
import { PortalTypes } from '@/types/enums/global'
import { INavigationItem } from '@/types/interfaces/ui'

export const navigationItems: INavigationItem[] = [
  // Site portal menu items
  {
    id: 'gate',
    title: 'Gate',
    icon: <LocalShippingOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: SitePortalPermissions) =>
      portalType === PortalTypes.Site &&
      (permissions.gate_queue || permissions.gate_transaction),
    link: ROUTES.GATE,
    items: [
      {
        id: 'queues',
        title: 'Queue',
        link: ROUTES.QUEUES,
        visible: (permissions: SitePortalPermissions) => permissions.gate_queue
      },
      {
        id: 'transactions',
        title: 'Transactions',
        link: ROUTES.TRANSACTIONS,
        visible: (permissions: SitePortalPermissions) =>
          permissions.gate_transaction
      }
    ],
    disableAccordion: true
  },
  {
    id: 'yard',
    title: 'Yard',
    icon: <ContentPasteOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: SitePortalPermissions) => {
      const hasAccessToCargoAsset = permissions.onsite_cargo_asset
      const hasAccessToDrivers = permissions.onsite_driver

      const mainItemVisible = hasAccessToCargoAsset || hasAccessToDrivers

      return portalType === PortalTypes.Site && mainItemVisible
    },
    items: [
      {
        id: 'cargo-assets',
        title: 'Cargo Assets',
        link: ROUTES.CARGO_ASSETS,
        visible: (permissions: SitePortalPermissions) =>
          permissions.onsite_cargo_asset
      },
      {
        id: 'drivers',
        title: 'Drivers',
        link: ROUTES.DRIVERS,
        visible: (permissions: SitePortalPermissions) =>
          permissions.onsite_driver
      }
    ]
  },
  {
    id: 'isr',
    title: 'ISR',
    link: ROUTES.ISR,
    icon: <WbSunnyOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: SitePortalPermissions) =>
      portalType === PortalTypes.Site && permissions.isr
  },

  // Enterprise menu items
  {
    id: 'enterprise-gate-transactions',
    title: 'Gate',
    link: ROUTES.ENTERPRISE_TRANSACTIONS,
    icon: <LocalShippingOutlinedIcon />,
    visible: (portalType: PortalTypes) => portalType === PortalTypes.Enterprise
  },
  {
    id: 'enterprise-yard',
    title: 'Yard',
    icon: <ContentPasteOutlinedIcon />,
    visible: (portalType: PortalTypes) => portalType === PortalTypes.Enterprise,
    items: [
      {
        id: 'cargo-asset',
        title: 'Cargo Assets',
        link: ROUTES.ENTERPRISE_CARGO_ASSETS,
        visible: () => true
      },
      {
        id: 'drivers',
        title: 'Drivers',
        link: ROUTES.ENTERPRISE_DRIVERS,
        visible: () => true
      }
    ]
  },
  {
    id: 'enterprise-isr',
    title: 'ISR',
    link: ROUTES.ENTERPRISE_ISR,
    icon: <WbSunnyOutlinedIcon />,
    visible: (portalType: PortalTypes) => portalType === PortalTypes.Enterprise
  },
  {
    id: 'users',
    title: 'Users',
    link: ROUTES.ENTERPRISE_USERS,
    icon: <GroupOutlinedIcon />,
    visible: (portalType: PortalTypes) => portalType === PortalTypes.Enterprise
  }
]
