import { useSnackbar } from '@/features/snackbars-queue'
import { useNavigator } from '@/router'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

interface Props<T> {
  data: T[] | undefined
  loadingData: boolean
  parameterId: string
  getEntity: (entities: T[], id: string) => T | undefined
  entityNotFoundMessage?: string
}

const useTableEntity = <T>({
  data,
  loadingData,
  parameterId,
  getEntity,
  entityNotFoundMessage = 'Could not find resource'
}: Props<T>) => {
  const [tableEntity, setTableEntity] = useState<T | undefined>()

  const { showErrorSnackbar } = useSnackbar()

  const params = useParams()
  const location = useLocation()
  const navigator = useNavigator()

  useEffect(() => {
    const entityId = params[parameterId]

    if (!entityId || loadingData) return

    const foundEntity = getEntity(data as T[], entityId)

    if (!foundEntity) {
      navigator.goBackOneLevel()
      showErrorSnackbar(entityNotFoundMessage)
      return
    }

    setTableEntity(foundEntity)
  }, [parameterId, data, loadingData, location])

  return { tableEntity }
}

export default useTableEntity
