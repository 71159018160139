import { useMemo } from 'react'

import { Loader } from '@/components/atoms'
import { ErrorTemplate, WithHeaderTemplate } from '@/components/templates'
import { ERRORS } from '@/constants'
import { EnterpriseLanding } from '@/features/home/component'
import { useStore } from '@/store'
import { PortalTypes } from '@/types/enums/global'

const HomeTemplate = () => {
  const { selectedPortal, selectedGate } = useStore((store) => store.user)

  const isEnterprise = selectedPortal?.type === PortalTypes.Enterprise

  const errorDetails = useMemo(() => {
    if (!selectedGate) return ERRORS.NO_GATES

    if (!selectedPortal) return ERRORS.NO_PORTALS

    if (!selectedPortal.permissions) return ERRORS.NO_PERMISSIONS

    return undefined
  }, [selectedPortal, selectedGate])

  if (isEnterprise) return <EnterpriseLanding />

  return errorDetails ? (
    <WithHeaderTemplate title="Home">
      <ErrorTemplate
        title={errorDetails.title}
        description={errorDetails.description}
      />
    </WithHeaderTemplate>
  ) : (
    <Loader fullScreen />
  )
}

export default HomeTemplate
