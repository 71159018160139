import React, { ReactNode, useState } from 'react'

import { Row, Text } from '@/components/atoms'
import { FormCol, FormRow, FormSection } from '@/features/forms/components'
import { NameType } from '@/features/forms/enums'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import { Id, LicenseState, Name, Phone } from './fields'
import { FORM_SECTION_ANCHORS } from '@/features/forms/constants'

import styles from './DriverDetailsFormSection.module.scss'

export enum DriverSectionTitleAppearance {
  Primary = 'primary',
  Secondary = 'secondary'
}

const titleAppearanceMapper = {
  [DriverSectionTitleAppearance.Primary]: {
    type: TextTypes.TEXT_MD,
    color: Color.gray700
  },

  [DriverSectionTitleAppearance.Secondary]: {
    type: TextTypes.TEXT_SM,
    color: Color.gray600
  }
}

interface IProps {
  title?: string
  titleAppearance?: DriverSectionTitleAppearance
  readOnly?: boolean
  collapsed?: boolean
  Action?: ReactNode
  idPrefix?: string
  withoutWrapper?: boolean
}

const DriverDetailsFormSection = (props: IProps) => {
  const {
    readOnly,
    Action,
    withoutWrapper,
    collapsed,
    idPrefix,
    title = 'Driver Details',
    titleAppearance = DriverSectionTitleAppearance.Primary
  } = props

  const [selectedDriverId, setSelectedDriverId] = useState<string | undefined>()

  const titleParams = titleAppearanceMapper[titleAppearance]

  const Fields = (
    <FormCol id={FORM_SECTION_ANCHORS.DRIVER.ID}>
      <FormRow>
        <Name
          idPrefix={idPrefix}
          selectedDriverId={selectedDriverId}
          setSelectedDriverId={setSelectedDriverId}
          type={NameType.First}
          readOnly={readOnly}
        />
        <Name
          idPrefix={idPrefix}
          selectedDriverId={selectedDriverId}
          setSelectedDriverId={setSelectedDriverId}
          type={NameType.Last}
          readOnly={readOnly}
        />
      </FormRow>

      <FormRow>
        <Id idPrefix={idPrefix} readOnly={readOnly} />
        <LicenseState idPrefix={idPrefix} readOnly={readOnly} />
      </FormRow>

      <Phone idPrefix={idPrefix} readOnly={readOnly} />
    </FormCol>
  )

  return withoutWrapper ? (
    <FormCol className={styles.secondarySection}>
      <Row
        items="center"
        justify="between"
        gap={20}
        className={styles.titleWithBorder}
      >
        <Text
          type={titleParams.type}
          color={titleParams.color}
          weight={FontWeight.SEMIBOLD}
        >
          {title}
        </Text>

        {Action}
      </Row>

      {Fields}
    </FormCol>
  ) : (
    <FormSection Action={Action} title={title} collapsed={collapsed}>
      {Fields}
    </FormSection>
  )
}

DriverDetailsFormSection.Id = Id
DriverDetailsFormSection.Name = Name
DriverDetailsFormSection.Phone = Phone
DriverDetailsFormSection.LicenseState = LicenseState

export default DriverDetailsFormSection
