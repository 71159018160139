import { Autocomplete, AutocompleteProps, ListItem } from '@mui/material'
import React, { FC, memo, ReactNode, useMemo } from 'react'

import { AutocompleteDropdown, Input } from '@/components/atoms'
import { TextInputProps } from '@/types/interfaces/ui'

import styles from './Autocomplete.module.scss'

interface IProps
  extends Omit<
    AutocompleteProps<
      any,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'renderInput' | 'renderOption'
  > {
  name?: string
  freeSolo?: boolean
  onChange: (value: any) => void
  isOptionEqualToValue: (option: any, value: any) => boolean
  required?: boolean
  helperText?: string
  label?: string
  error?: boolean
  loading?: boolean
  hideControls?: boolean
  customOptionLabel?: (option: any) => string | ReactNode
  inputProps?: Partial<TextInputProps>
}

const CustomAutocomplete: FC<IProps> = (props) => {
  const {
    name,
    label,
    options,
    onChange,
    value,
    title,
    freeSolo = true,
    required,
    helperText,
    error,
    fullWidth = true,
    loading = false,
    hideControls = false,
    isOptionEqualToValue,
    customOptionLabel,
    inputProps = {},
    inputValue,
    ...restProps
  } = props

  const customValue = useMemo(
    () =>
      options.find((option) => isOptionEqualToValue(option, inputValue)) ||
      null,
    [isOptionEqualToValue, inputValue, options]
  )

  return (
    <Autocomplete
      {...restProps}
      disablePortal
      freeSolo={freeSolo}
      options={options}
      loading={loading}
      value={value || customValue}
      inputValue={inputValue}
      fullWidth={fullWidth}
      disableClearable={hideControls}
      forcePopupIcon={!hideControls}
      classes={{ endAdornment: styles.endAdornment }}
      renderOption={
        customOptionLabel
          ? (optionProps, option) => (
              <ListItem {...optionProps}>{customOptionLabel(option)}</ListItem>
            )
          : undefined
      }
      renderInput={(params) => (
        <Input
          {...params}
          {...inputProps}
          name={name}
          onChange={params.inputProps.onChange}
          InputProps={{
            ...params.InputProps,
            // Removes default browser autocomplete
            autoComplete: 'off'
          }}
          error={error}
          helperText={helperText}
          required={required}
          label={label}
          className={styles.autocompleteInput}
        />
      )}
      onChange={(e, newValue) => {
        onChange(newValue || undefined)
      }}
      slots={{
        paper: ({ children }) => (
          <AutocompleteDropdown
            title={title}
            showTitle={!inputValue && !!options.length}
          >
            {children}
          </AutocompleteDropdown>
        )
      }}
      slotProps={{
        clearIndicator: { disableRipple: true, className: styles.icon },
        popupIndicator: { disableRipple: true, className: styles.icon },
        popper: { className: styles.popper },
        listbox: { className: styles.autocompleteDropdown }
      }}
    />
  )
}

export default memo(CustomAutocomplete)
