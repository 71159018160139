import ROUTES from '@/router/routes'
import { useMatch } from 'react-router-dom'

/**
 * Returns the siteId, gateId, and remainder of the URL path.
 * For example, if the current URL is `/site/1/gate/2/queues`,
 * this hook will return 1 for siteId, 2 for gateId, and `queues`
 * for the remainder.
 */
export function useGatePathParts() {
  const match = useMatch(ROUTES.GATE + '/*')
  if (!match) return { siteId: null, remainder: null }

  const { site_id, '*': remainder } = match.params

  return {
    siteId: site_id ?? null,
    remainder: remainder ?? ''
  }
}
