import { StringOperator } from '@/__generated__/graphql'
import {
  GetCargoAssetsOnsiteQuery,
  GetEnterpriseCargoAssetsOnsiteQuery
} from '@/features/gate/api/queries'
import { useStore } from '@/store'
import { LoadTypes } from '@/types/enums/transactionDetails'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

interface Props {
  query: any
  enterpriseMode?: boolean
  filters: any
}

const useCargoAssetRollups = ({ query, enterpriseMode, filters }: Props) => {
  const { selectedPortal } = useStore((store) => store.user)
  const site_id = selectedPortal?.id || ''

  const { loadStatus: _ignore, ...remainingFiltersWithoutLoadStatus } = filters

  const loadStatuses = filters?.loadStatus?.values as string[] | undefined

  const skipLoaded =
    !!loadStatuses &&
    loadStatuses.length > 0 &&
    !loadStatuses.includes(LoadTypes.Loaded)

  const skipEmpty =
    !!loadStatuses &&
    loadStatuses.length > 0 &&
    !loadStatuses.includes(LoadTypes.Empty)

  const dataModeQuery = enterpriseMode
    ? GetEnterpriseCargoAssetsOnsiteQuery
    : GetCargoAssetsOnsiteQuery

  const { loading: loadingEmptyData, data: emptyData } = useQuery(
    dataModeQuery,
    {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutLoadStatus,
            loadStatus: {
              operator: StringOperator.In,
              values: [LoadTypes.Empty]
            }
          }
        }
      },
      skip: skipEmpty
    }
  )

  const { loading: loadingLoadedData, data: loadedData } = useQuery(
    dataModeQuery,
    {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutLoadStatus,
            loadStatus: {
              operator: StringOperator.In,
              values: [LoadTypes.Loaded]
            }
          }
        }
      },
      skip: skipLoaded
    }
  )

  const emptyDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (emptyData as any)?.listEnterpriseCargoAssetsOnSites?.pagination
          .totalItemsCount || 0
      )
    }

    return emptyData?.listCargoAssetsOnSite?.pagination.totalItemsCount || 0
  }, [emptyData, loadingEmptyData, filters, enterpriseMode])

  const loadedDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (loadedData as any)?.listEnterpriseCargoAssetsOnSites?.pagination
          .totalItemsCount || 0
      )
    }

    return loadedData?.listCargoAssetsOnSite?.pagination.totalItemsCount || 0
  }, [loadedData, loadingLoadedData, filters, enterpriseMode])

  return {
    loadingEmptyData,
    emptyDataCount,
    loadingLoadedData,
    loadedDataCount
  }
}

export default useCargoAssetRollups
