import { gql } from '@/__generated__'

gql(`
  fragment isrReportDetails on ISREventPair {
    organizationId,
    siteId,
        
    powerUnitLicensePlateNumber,
    powerUnitLicensePlateState,
        
    ciEventId,
    ciCorrelationId,
    ciEventUser,
    ciEventTime,
    ciMetadata {
        ...gateTransactionMetadata
    },
    ciLaneDisplayName,
        
    coEventId,
    coCorrelationId,
    coEventUser,
    coEventTime,
    coMetadata {
        ...gateTransactionMetadata
    },
    coLaneDisplayName,
        
    dwellTime
  }
`)

export const GetISRReportsQuery = gql(`
  query ListIsrReports($input: ListISRRecordsInput!) {
    listISRRecords(input: $input) {
      ISRRecords {
        ...isrReportDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)

export const GetEnterpriseISRReportsQuery = gql(`
  query ListEnterpriseIsrReports($input: ListEnterpriseISRRecordsInput!) {
    listEnterpriseISRRecords(input: $input) {
      ISRRecords {
        ...isrReportDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)
