import { StringOperator } from '@/__generated__/graphql'
import { useStore } from '@/store'
import { EmissionType } from '@/types/enums/transactionDetails'
import { useQuery } from '@apollo/client'
import {
  GetEnterpriseISRReportsQuery,
  GetISRReportsQuery
} from '../api/queries'
import { useMemo } from 'react'

interface Props {
  query: any
  enterpriseMode?: boolean
  filters: any
}

const useIsrRollups = ({ query, enterpriseMode = false, filters }: Props) => {
  const { selectedPortal } = useStore((store) => store.user)
  const site_id = selectedPortal?.id || ''

  const {
    powerUnitEmissionType: _ignore,
    ...remainingFiltersWithoutPowerUnitEmissionType
  } = filters

  const emissionTypes = filters?.powerUnitEmissionType?.values as
    | string[]
    | undefined

  const skipEmission =
    !!emissionTypes &&
    emissionTypes.length > 0 &&
    !emissionTypes.includes(EmissionType.E)

  const skipNearZeroEmission =
    !!emissionTypes &&
    emissionTypes.length > 0 &&
    !emissionTypes.includes(EmissionType.NZE)

  const skipZeroEmission =
    !!emissionTypes &&
    emissionTypes.length > 0 &&
    !emissionTypes.includes(EmissionType.ZE)

  const dataModeQuery = enterpriseMode
    ? GetEnterpriseISRReportsQuery
    : GetISRReportsQuery

  const { loading: loadingEmissions, data: emissionData } = useQuery(
    dataModeQuery,
    {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutPowerUnitEmissionType,
            powerUnitEmissionType: {
              operator: StringOperator.In,
              values: [EmissionType.E]
            }
          }
        }
      },
      skip: skipEmission
    }
  )

  const { loading: loadingNearZeroEmissions, data: nearZeroEmissionData } =
    useQuery(dataModeQuery, {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutPowerUnitEmissionType,
            powerUnitEmissionType: {
              operator: StringOperator.In,
              values: [EmissionType.NZE]
            }
          }
        }
      },
      skip: skipNearZeroEmission
    })

  const { loading: loadingZeroEmissions, data: zeroEmissionData } = useQuery(
    dataModeQuery,
    {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutPowerUnitEmissionType,
            powerUnitEmissionType: {
              operator: StringOperator.In,
              values: [EmissionType.ZE]
            }
          }
        }
      },
      skip: skipZeroEmission
    }
  )

  const emissionDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (emissionData as any)?.listEnterpriseISRRecords.pagination
          .totalItemsCount || 0
      )
    }

    return emissionData?.listISRRecords.pagination.totalItemsCount || 0
  }, [emissionData, loadingEmissions, filters, enterpriseMode])

  const nearZeroEmissionDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (nearZeroEmissionData as any)?.listEnterpriseISRRecords.pagination
          .totalItemsCount || 0
      )
    }

    return nearZeroEmissionData?.listISRRecords.pagination.totalItemsCount || 0
  }, [nearZeroEmissionData, loadingNearZeroEmissions, filters, enterpriseMode])

  const zeroEmissionDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (zeroEmissionData as any)?.listEnterpriseISRRecords.pagination
          .totalItemsCount || 0
      )
    }

    return zeroEmissionData?.listISRRecords.pagination.totalItemsCount || 0
  }, [zeroEmissionData, loadingZeroEmissions, filters, enterpriseMode])

  return {
    loadingEmissions,
    emissionDataCount,
    loadingNearZeroEmissions,
    nearZeroEmissionDataCount,
    loadingZeroEmissions,
    zeroEmissionDataCount
  }
}

export default useIsrRollups
