import { Chip, Row, Text } from '@/components/atoms'
import { ChipVariant } from '@/components/atoms/Chip/Chip'
import { useTableContext } from '@/components/contexts'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { IFilterValues } from '@/types/interfaces/table'
import FilterListIcon from '@mui/icons-material/FilterList'
import clsx from 'clsx'
import { useMemo } from 'react'
import styles from './RollupStat.module.scss'

interface Props {
  label: string
  value: string | number
  statusChip?: string
  chipType?: string
  filter?: IFilterValues
  loading?: boolean
}

const RollupStat = ({
  label,
  value,
  statusChip,
  chipType = 'success',
  filter,
  loading
}: Props) => {
  const filterable = !!filter
  const { filterValues, setFilterValues } = useTableContext()

  // Determine if the filter is active
  const filterActive = useMemo(() => {
    if (!filterable || !filter) return false

    return Object.keys(filter).some((key) => {
      const filterValue = filter[key]
      const currentFilterValue = filterValues[key]

      // Deep comparison for different types of values
      if (Array.isArray(filterValue) && Array.isArray(currentFilterValue)) {
        return filterValue.every((val) => currentFilterValue.includes(val))
      }

      return JSON.stringify(filterValue) === JSON.stringify(currentFilterValue)
    })
  }, [filterValues, filter, filterable])

  const toggleFilter = () => {
    if (!filterable) return

    const filterKeys = Object.keys(filter || {})
    let newFilterValues: IFilterValues
    if (filterActive) {
      newFilterValues = filterKeys.reduce(
        (acc, key) => {
          const { [key]: _, ...rest } = acc
          return rest
        },
        { ...filterValues }
      )
    } else {
      newFilterValues = { ...filterValues, ...filter }
    }

    setFilterValues(newFilterValues)
  }

  return (
    <div
      onClick={toggleFilter}
      className={clsx(
        styles.rollupStat,
        filterable && styles.filterable,
        filterActive && styles.active
      )}
    >
      <Row className={styles.labelRow}>
        <Text
          className={styles.label}
          type={TextTypes.TEXT_SM}
          weight={FontWeight.REGULAR}
        >
          {loading ? '-' : label}
        </Text>

        {filterable && <FilterListIcon className={styles.filterIcon} />}
      </Row>
      <Row className={styles.statRow}>
        <Text type={TextTypes.TEXT_XL} weight={FontWeight.BOLD}>
          {value}
        </Text>
        {statusChip && (
          <Chip type={chipType as ChipVariant} label={statusChip} />
        )}
      </Row>
    </div>
  )
}

export default RollupStat
