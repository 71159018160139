import styles from './HeaderContainer.module.scss'

interface HeaderContainer {
  children?: React.ReactNode
  chartXPadding: number
}

const HeaderContainer = ({ children, chartXPadding }: HeaderContainer) => {
  return (
    <div
      className={styles.headerContainer}
      style={
        { '--chart-x-padding': `${chartXPadding}px` } as React.CSSProperties
      }
    >
      {children}
    </div>
  )
}

export default HeaderContainer
