import { GateWithLanes } from '@/features/gate/types'

const findGateBasedOnLaneId = (
  gates: GateWithLanes[] | undefined,
  laneId: string
): GateWithLanes | undefined => {
  if (!gates) return undefined

  const gate = gates.find((gateItem) =>
    gateItem.lanes.some((lane) => lane.id === laneId)
  )

  return gate
}

export default findGateBasedOnLaneId
