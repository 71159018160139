import { LegendOrdinal } from '@visx/legend'
import { scaleOrdinal } from '@visx/scale'
import CustomLegendLabel from './LegendLabel'

import { Row } from '@/components/atoms'
import { useMemo } from 'react'
import { TimeseriesConfig } from '../helpers/TimeseriesConfig'
import styles from './TimeseriesLegend.module.scss'

interface TimeseriesLegendProps {
  colors: string[]
  series: TimeseriesConfig[]
}

const TimeseriesLegend = ({ colors, series }: TimeseriesLegendProps) => {
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: series.map((s) => s.label),
        range: colors
      }),
    [colors, series]
  )

  const getValue = (index: number) => {
    return series[index].data[series[index].data.length - 1]?.y
  }

  return (
    <Row className={styles.timeseriesLegend}>
      {series.length !== 1 && (
        <LegendOrdinal scale={colorScale} direction="row">
          {(labels) =>
            labels.map((label, i) => (
              <CustomLegendLabel
                key={`legend-ordinal-${label.text}-${i}`}
                lineColor={label.value}
                index={i}
                labelText={label.text}
                currentValue={getValue(i)}
              />
            ))
          }
        </LegendOrdinal>
      )}
    </Row>
  )
}

export default TimeseriesLegend
