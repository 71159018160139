import { TickRendererProps } from '@visx/axis'

const YAxisTickLabel = ({ x, y, formattedValue }: TickRendererProps) => {
  const X_SHIFT = -30
  const CIRCLE_Y_SHIFT = 0
  const TEXT_Y_SHIFT = 4
  const FONT_SIZE = 12
  const OPACITY = 0.8
  const PILL_FILL = 'white'
  const PILL_BORDER = '#E5E7EB'
  const PILL_BORDER_LENGTH = 1
  const TEXT_COLOR = '#6B7280'

  const PILL_WIDTH = 32
  const PILL_HEIGHT = 20
  const PILL_RADIUS = PILL_HEIGHT / 2

  return (
    <g transform={`translate(${x}, ${y})`} opacity={OPACITY}>
      <rect
        x={X_SHIFT - PILL_WIDTH / 2}
        y={CIRCLE_Y_SHIFT - PILL_HEIGHT / 2}
        width={PILL_WIDTH}
        height={PILL_HEIGHT}
        rx={PILL_RADIUS}
        ry={PILL_RADIUS}
        fill={PILL_FILL}
        stroke={PILL_BORDER}
        strokeWidth={PILL_BORDER_LENGTH}
      />
      <text
        x={X_SHIFT}
        y={TEXT_Y_SHIFT}
        textAnchor="middle"
        fill={TEXT_COLOR}
        fontSize={FONT_SIZE}
      >
        {formattedValue}
      </text>
    </g>
  )
}

export default YAxisTickLabel
