import { Col, Text } from '@/components/atoms'
import useViewportSize from '@/hooks/useViewportSize'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import clsx from 'clsx'
import { useState } from 'react'
import styles from './AppBanner.module.scss'

interface AppBannerProps {
  header: string
  headerIcon?: React.ReactNode
  body?: string
}

const AppBanner = ({ header, headerIcon, body }: AppBannerProps) => {
  const { isSmallView } = useViewportSize()

  const [descriptionHidden, setDescriptionHidden] = useState<boolean>(false)

  return (
    <div className={styles.banner}>
      <div className={styles.leftBannerContent}>
        <div className={styles.iconAndHeader}>
          {headerIcon}
          <Text type={TextTypes.TEXT_SM} weight={FontWeight.SEMIBOLD}>
            {header}
          </Text>
        </div>
        {!(descriptionHidden && isSmallView) && (
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.REGULAR}
            className={styles.body}
          >
            {body}
          </Text>
        )}
      </div>
      <Col className={styles.accordionButtonContainer}>
        {isSmallView && (
          <div
            onClick={() => setDescriptionHidden(!descriptionHidden)}
            className={clsx(
              styles.accordionButton,
              descriptionHidden && styles.toggled
            )}
          >
            <ExpandLessIcon />
          </div>
        )}
      </Col>
    </div>
  )
}

export default AppBanner
