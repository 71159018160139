import { ErrorTemplate } from '@/components/templates'
import { ERRORS, IErrorTemplate } from '@/constants'
import { SitePortalPermissions } from '@/features/auth/types'
import { useStore } from '@/store'

interface Props {
  children?: React.ReactNode
  requiredPermission: keyof SitePortalPermissions
  errorTemplate?: IErrorTemplate
}

/**
 * A protection route which guards against showing the provided
 *  child if the user does not have the required permission.
 */
const ProtectedRoute = ({
  children,
  requiredPermission,
  errorTemplate = ERRORS.NO_PERMISSIONS
}: Props) => {
  const { selectedPortal } = useStore((store) => store.user)
  const permissions =
    (selectedPortal?.permissions as SitePortalPermissions) || []
  const permittedToView = permissions[requiredPermission]

  if (!permittedToView) {
    return (
      <ErrorTemplate
        title={errorTemplate.title}
        description={errorTemplate.description}
      />
    )
  }

  return <>{children}</>
}

export default ProtectedRoute
