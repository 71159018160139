import { useLocalStorage } from 'usehooks-ts'
import { ColumnState } from 'ag-grid-community'
import { useEffect } from 'react'
import { KeyCode } from '@/types/enums/ui'

interface Props {
  name: string
  columnsVersion: string
}

const useTableColumnsState = (props: Props) => {
  const { name, columnsVersion } = props

  const [tableColumnState, setTableColumnState] = useLocalStorage<
    ColumnState[]
  >(`${name}-grid-column-state_${columnsVersion}`, [])

  useEffect(() => {
    if (name && columnsVersion) {
      Object.keys(localStorage).filter((key) => {
        // Last element from key is a version
        const version = key.split(KeyCode.Underscore).at(-1)

        if (key.startsWith(name) && version !== columnsVersion) {
          localStorage.removeItem(key)
        }
      })
    }
  }, [name, columnsVersion])

  return {
    tableColumnState,
    setTableColumnState
  }
}

export default useTableColumnsState
