import { Col, Text } from '@/components/atoms'

import styles from './NoDataAvailable.module.scss'
import { TextTypes } from '@/types/enums/ui'

const NoDataAvailable = () => {
  return (
    <Col items="center" justify="center" className={styles.textLabelsContainer}>
      <Text type={TextTypes.TEXT_MD} className={styles.label}>
        No data avilable
      </Text>
      <Text type={TextTypes.TEXT_SM} className={styles.description}>
        Try changing your selected filters.
      </Text>
    </Col>
  )
}

export default NoDataAvailable
