import { Axis } from '@visx/xychart'
import YAxisTickLabel from './YAxisTickLabel'

interface YAxisLabelsProps {
  numTicks?: number
}

const YAxisLabels = ({ numTicks = 5 }: YAxisLabelsProps) => {
  return (
    <Axis
      orientation="right"
      hideAxisLine
      numTicks={numTicks}
      hideZero
      hideTicks
      tickComponent={YAxisTickLabel}
    />
  )
}

export default YAxisLabels
