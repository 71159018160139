import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Collapse } from '@mui/material'
import clsx from 'clsx'
import {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useState
} from 'react'

import { Button, Col, Row, Text } from '@/components/atoms'
import { ToggleDetailsButton } from '@/components/molecules'
import { COLLAPSE_ANIMATION_DURATION } from '@/constants'
import { DeleteFormSectionModal } from '@/features/forms/components'
import { useModal } from '@/hooks'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import useViewportSize from '@/hooks/useViewportSize'
import styles from './FormSection.module.scss'

interface IProps extends PropsWithChildren {
  title: string
  collapsed?: boolean
  onlyCollapsibleOnMobile?: boolean
  id?: string
  Action?: ReactNode
  TitleSuffix?: ReactNode
  onRemove?: () => void
}

export const FORM_SECTION_PORTAL_ID = 'form-section-portal'

const FormSection: FC<IProps> = (props) => {
  const {
    id,
    title,
    children,
    TitleSuffix,
    Action,
    collapsed = false,
    onlyCollapsibleOnMobile = false,
    onRemove
  } = props

  const [open, setOpen] = useState<boolean>(!collapsed)

  const { visible, closeModal, openModal } = useModal<boolean>(false)

  const { isSmallView } = useViewportSize()

  const collapsible = useMemo(() => {
    if (!onlyCollapsibleOnMobile) return true

    return onlyCollapsibleOnMobile && isSmallView
  }, [isSmallView, onlyCollapsibleOnMobile])

  const toggleOpen = () => collapsible && setOpen(!open)

  const onDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    openModal()
  }

  return (
    <>
      {visible && (
        <DeleteFormSectionModal
          title={title}
          onDelete={onRemove!}
          closeModal={closeModal}
        />
      )}

      <Row id={FORM_SECTION_PORTAL_ID} />
      <Col id={id} items="stretch" className={styles.formSection}>
        <Row
          gap={10}
          items="center"
          justify="between"
          onClick={toggleOpen}
          className={clsx({
            [styles.collapsibleHeader]: collapsible
          })}
        >
          <Row items="center" gap={8}>
            <Text
              type={TextTypes.TEXT_MD}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray700}
            >
              {title}
            </Text>

            {TitleSuffix}
          </Row>

          <Row items="center" gap={16}>
            {open && Action}

            {!!onRemove && (
              <Button
                type="dangerText"
                startIcon={<DeleteOutlineIcon />}
                onClick={onDeleteClick}
              >
                Delete
              </Button>
            )}

            {collapsible && <ToggleDetailsButton arrow showDetails={open} />}
          </Row>
        </Row>

        <Collapse in={open} timeout={COLLAPSE_ANIMATION_DURATION}>
          <div className={styles.collapsedContent}>{children}</div>
        </Collapse>
      </Col>
    </>
  )
}

export default FormSection
