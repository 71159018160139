interface TimeseriesIntersectionGlyphProps {
  color: string
}

const TimeseriesIntersectionGlyph = ({
  color
}: TimeseriesIntersectionGlyphProps) => {
  return <circle r={5} fill={'white'} stroke={color} strokeWidth={2} />
}

export default TimeseriesIntersectionGlyph
