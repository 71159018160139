import { GridApi } from 'ag-grid-community'

/**
 * Autosizes all currently visible AG Grid columns their maximum possible widths given the provided space.
 * The columns will never be set below their minimum possible width as specified by the grid's autoSizeColumns() method.
 */
const autosizeVisibleAgGridColumns = (gridApi: GridApi) => {
  if (!gridApi) return
  // Extremely rare case where the gridApi returns undefined for getColumnState()
  const columns = gridApi.getColumnState() ?? []
  const visibleColumns = columns.filter((column) => !column.hide)
  gridApi.autoSizeColumns(visibleColumns.map((column) => column.colId))
}

export default autosizeVisibleAgGridColumns
