import { Pie } from '@visx/shape'

interface NoDataPieProps {
  outerRadius: number
  innerRadius: number
  sectionSpacing: number
  title?: string
  fill?: string
}

const DEFAULT_NO_DATA_PIE_FILL = '#D1D5DB'

const NoDataPie = ({
  outerRadius,
  innerRadius,
  sectionSpacing,
  title = 'No Data',
  fill = DEFAULT_NO_DATA_PIE_FILL
}: NoDataPieProps) => {
  return (
    <Pie
      data={[{ title, value: 1 }]}
      pieValue={(section) => section.value}
      outerRadius={outerRadius}
      innerRadius={innerRadius}
      padAngle={sectionSpacing}
    >
      {(pie) =>
        pie.arcs.map((arc, i) => (
          <g key={`no-data-pie-arc-${i}`}>
            <path d={pie.path(arc) ?? ''} fill={fill} />
          </g>
        ))
      }
    </Pie>
  )
}

export default NoDataPie
