import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { Collapse, Fade } from '@mui/material'
import clsx from 'clsx'
import { KeyboardEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Row, Text, Tooltip } from '@/components/atoms'
import { CollapsedNavigationPopover } from '@/components/organisms/Navigation/components'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { INavigationItem, INavigationSubItem } from '@/types/interfaces/ui'
import { onEnterPressed } from '@/utils/helpers'

import { useGatePathParts } from '@/hooks/useGatePathParts'
import styles from './NavigationItem.module.scss'

interface NavigationItemProps {
  item: INavigationItem
  sidebarCollapsed: boolean
  isExpanded: boolean
  onExpand: (id: string) => void
}

const NavigationItem = ({
  item,
  isExpanded,
  onExpand,
  sidebarCollapsed
}: NavigationItemProps) => {
  const { id, icon, title, link, items, disableAccordion, onClick } = item

  const location = useLocation()
  const { remainder } = useGatePathParts()

  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLElement | null>(null)

  const itemHasChildren = Array.isArray(items)
  const showSubItems = isExpanded && !sidebarCollapsed

  const parentItemActive = useMemo(() => {
    if (!link) return false
    if (location.pathname === link) return true
    if (itemHasChildren) {
      return items.some((subItem) => location.pathname.startsWith(subItem.link))
    }
    return false
  }, [link, items, location.pathname])

  const childItemActive = useMemo(() => {
    if (parentItemActive) return false
    return items?.some((item) => item.id === remainder)
  }, [parentItemActive, items, remainder])

  const showParentBackground =
    parentItemActive || (childItemActive && sidebarCollapsed)
  const boldText = parentItemActive || childItemActive

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (link) return

    if (onClick) {
      onClick()
      return
    }

    if (sidebarCollapsed) {
      setPopoverAnchorElement(e.currentTarget)
      return
    }

    if (itemHasChildren && !disableAccordion) {
      onExpand(id)
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    onEnterPressed(event, () =>
      handleClick({
        currentTarget: event.currentTarget
      } as MouseEvent<HTMLElement>)
    )
  }

  // Hide popup if sidebar is not minimized
  useEffect(() => {
    if (!sidebarCollapsed) {
      setPopoverAnchorElement(null)
    }
  }, [sidebarCollapsed])

  const renderParentItem = () => {
    const ParentItem = link ? Link : Row

    return (
      <ParentItem
        tabIndex={0}
        key={id}
        to={link as string}
        onClick={handleClick}
        onKeyDown={handleKeyPress}
        className={clsx(styles.navItem, showParentBackground && styles.active)}
      >
        {icon}

        <Fade in={!sidebarCollapsed} timeout={500} appear={false}>
          <div>
            <Text
              type={TextTypes.TEXT_SM}
              weight={boldText ? FontWeight.BOLD : FontWeight.REGULAR}
              color={Color.gray300}
            >
              {title}
            </Text>

            {itemHasChildren && !disableAccordion && (
              <div className={clsx(styles.arrow, isExpanded && styles.opened)}>
                <KeyboardArrowDownOutlinedIcon />
              </div>
            )}
          </div>
        </Fade>
      </ParentItem>
    )
  }

  const renderSubItem = (subItem: INavigationSubItem) => {
    const isActive = location.pathname === subItem.link

    return (
      <Fade in={showSubItems} timeout={500} key={subItem.id} appear={false}>
        <Link
          to={subItem.link}
          className={clsx(
            styles.navItem,
            styles.subItem,
            isActive && styles.active
          )}
        >
          <Text
            type={TextTypes.TEXT_SM}
            weight={isActive ? FontWeight.BOLD : FontWeight.REGULAR}
            color={Color.gray300}
          >
            {subItem.title}
          </Text>
        </Link>
      </Fade>
    )
  }

  return (
    <div className={styles.navItemWrapper}>
      <Tooltip show={sidebarCollapsed} placement="right" tooltip={title}>
        <div>{renderParentItem()}</div>
      </Tooltip>

      {itemHasChildren && (
        <Collapse timeout={600} in={showSubItems} unmountOnExit>
          <div>{items?.map(renderSubItem)}</div>
        </Collapse>
      )}

      {/* A popup which can be open by clicking on menu items which contains
      sub items when navigation is collapsed */}
      {sidebarCollapsed && itemHasChildren && (
        <CollapsedNavigationPopover
          name={`popover-${id}`}
          anchorEl={popoverAnchorElement}
          title={title}
          Icon={icon}
          onClose={() => setPopoverAnchorElement(null)}
          items={items as INavigationSubItem[]}
        />
      )}
    </div>
  )
}

export default NavigationItem
