export const DEFAULT_LOCALS = 'en-US'

const SHORT_MONTH_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric'
}

export const FULL_MONTH_DATE_YEAR_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
}

const tickFormatter = (
  value: any,
  locals: Intl.LocalesArgument = DEFAULT_LOCALS,
  options: Intl.DateTimeFormatOptions = SHORT_MONTH_DATE_OPTIONS
) => {
  const date = new Date(value as string)
  return date.toLocaleDateString(locals, options)
}

export default tickFormatter
