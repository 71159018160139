import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  cargoAssetTypesFriendlyNames,
  emissionTypesFriendlyNames,
  fuelTypesFriendlyNames,
  loadTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers'
import {
  appointmentTypeToBoolean,
  arrayToString,
  sealMatchesToBoolean,
  transactionTypeToDirection,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'
import {
  GateTransaction,
  OrderByDomainEventColumn
} from '@/__generated__/graphql'
import { UserPortalsListItem } from '@/features/auth/types'
import { orFallback } from '@/utils/helpers'
import { GateWithLanes } from '@/features/gate/types'
import { findGateBasedOnLaneId } from '@/features/gate/utils'

interface Props {
  enterpriseMode: boolean
  sites: UserPortalsListItem[]
  gates: GateWithLanes[]
}

export const TRANSACTIONS_COLUMNS_VERSION = '1.1.0'

export const transactionsColumns = ({
  enterpriseMode,
  sites,
  gates
}: Props): ITableColumn[] => [
  // TRANSACTION
  {
    id: 'eventTime',
    title: 'Transaction Time',
    type: TableColumnType.Date,
    sortable: true,
    sortId: OrderByDomainEventColumn.EventTime,
    defaultVisible: true
  },
  ...(enterpriseMode
    ? [
        {
          id: 'site',
          title: 'Site',
          type: TableColumnType.Text,
          converter: (row: GateTransaction) =>
            orFallback(sites.find((site) => site.id === row.siteId)?.name),
          defaultVisible: true
        }
      ]
    : gates.length > 1
      ? [
          {
            id: 'gate',
            title: 'Gate',
            type: TableColumnType.Text,
            converter: (row: GateTransaction) =>
              findGateBasedOnLaneId(gates, row.metadata.laneId)?.display_name,
            defaultVisible: true
          }
        ]
      : []),
  {
    id: 'transactionType',
    title: 'Direction',
    type: TableColumnType.Text,
    converter: transactionTypeToDirection,
    sortable: true,
    sortId: OrderByDomainEventColumn.TransactionType,
    defaultVisible: true
  },
  {
    id: 'type',
    title: 'Event Type',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      row.referenceId ? 'Detection' : 'Manual',
    sortable: true,
    sortId: OrderByDomainEventColumn.Type
  },
  {
    id: 'metadata.appointmentType',
    title: 'Visitor',
    type: TableColumnType.Text,
    converter: appointmentTypeToBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.AppointmentType
  },

  // POWER UNIT
  {
    id: 'metadata.powerUnitOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitOwnerId
  },
  {
    id: 'metadata.powerUnitLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitLicensePlateNumber
  },
  {
    id: 'metadata.powerUnitLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitLicensePlateState
  },
  {
    id: 'metadata.powerUnitCarrierUsdot',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitCarrierUsdot
  },
  {
    id: 'metadata.powerUnitCarrierMCNum',
    title: 'MC #',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitCarrierMcnum
  },
  {
    id: 'metadata.powerUnitVin',
    title: 'VIN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitVin
  },
  {
    id: 'metadata.powerUnitWeightClass',
    title: 'Class',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: weightClassesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitWeightClass,
    defaultVisible: true
  },
  {
    id: 'metadata.powerUnitFuelType',
    title: 'Fuel Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: fuelTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitFuelType
  },
  {
    id: 'metadata.powerUnitEmissionType',
    title: 'Emission',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: emissionTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitEmissionType
  },
  {
    id: 'metadata.powerUnitCarrierName',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitCarrierName
  },
  {
    id: 'metadata.accountName',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByDomainEventColumn.AccountName
  },

  // DRIVER DETAILS
  {
    id: 'driverName',
    title: 'Name',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      `${row.metadata.driverFirstName} ${row.metadata.driverLastName}`,
    sortable: true,
    sortId: OrderByDomainEventColumn.DriverFirstName
  },
  {
    id: 'metadata.driverLicenseNumber',
    title: 'License',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.DriverLicenseNumber
  },
  {
    id: 'metadata.driverPhoneNumber',
    title: 'Phone #',
    type: TableColumnType.Phone,
    sortable: true,
    sortId: OrderByDomainEventColumn.DriverPhoneNumber
  },

  // CARGO ASSET
  {
    id: 'metadata.cargoAssetOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetOwnerId,
    defaultVisible: true
  },
  {
    id: 'metadata.cargoAssetLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetLicensePlateNumber,
    defaultVisible: true
  },
  {
    id: 'metadata.cargoAssetLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetLicensePlateState,
    defaultVisible: true
  },
  {
    id: 'metadata.cargoAssetCarrierName',
    title: 'Asset Carrier',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetCarrierName,
    defaultVisible: true
  },
  {
    id: 'metadata.cargoAssetAssetType',
    title: 'Asset Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: cargoAssetTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetAssetType
  },
  {
    id: 'metadata.loadStatus',
    title: 'Load Status',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: loadTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.LoadStatus
  },
  {
    id: 'metadata.shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.ShipmentNumber
  },
  {
    id: 'metadata.sealNumbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByDomainEventColumn.SealNumbers
  },
  {
    id: 'metadata.sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.SealMatchPw
  },
  {
    id: 'metadata.inspectionCompleted',
    title: 'Inspection Completed',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      row.metadata.inspectionCompleted ? 'Yes' : '',
    sortable: true,
    sortId: OrderByDomainEventColumn.InspectionCompleted
  },
  {
    id: 'createdBy',
    title: 'Source',
    type: TableColumnType.Text
  },
  {
    id: 'metadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.Mismatch
  }
]

export default transactionsColumns
