export enum NameType {
  First = 'first',
  Last = 'last',
  Full = 'full'
}

export enum FormSectionType {
  PowerUnitAndDriver = 'powerUnitAndDriverDetails',
  CargoAsset = 'cargoAssetDetails'
}

export enum FormLengthErrorType {
  Max = 'Max',
  Min = 'Min'
}
