import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { useDispatch, useStore } from '@/store'
import { Button, DropdownMenu } from '@/components/atoms'
import { DropdownMenuItem } from '@/types/interfaces/ui'
import { orFallback } from '@/utils/helpers'
import { selectGate } from '@/features/auth/store'

import styles from './GateDropdown.module.scss'

const GateDropdown: FC = () => {
  const { selectedGate, gates = [] } = useStore((store) => store.user)
  const dispatch = useDispatch()

  const showSelector = gates.length > 1 && !!selectedGate

  const options: DropdownMenuItem[] = useMemo(
    () =>
      gates.map((gate) => ({
        id: gate.id,
        label: gate.display_name,
        selected: selectedGate?.id === gate.id
      })),
    [gates, selectedGate]
  )

  const onSelectGate = (id: string) => {
    dispatch(selectGate(id))
  }

  return (
    showSelector && (
      <DropdownMenu items={options} placement="right" onClick={onSelectGate}>
        {({ toggle, open }) => (
          <Button
            type="outlined"
            onClick={toggle}
            endIcon={
              <ArrowDropDownOutlinedIcon
                className={clsx({ [styles.mirroredArrow]: open })}
              />
            }
          >
            {orFallback(selectedGate?.display_name)}
          </Button>
        )}
      </DropdownMenu>
    )
  )
}

export default GateDropdown
