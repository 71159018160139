import { useEffect } from 'react'
import { useDocumentTitle } from './DocumentTitleContext'

interface TerminalDocumentTitleProps {
  prefix?: string
  title?: string
  suffix?: string
}

const TerminalDocumentTitle = ({
  prefix,
  title,
  suffix
}: TerminalDocumentTitleProps) => {
  const { setTitle, setTitleSuffix, setTitlePrefix } = useDocumentTitle()

  useEffect(() => {
    // Prefix is usually used for entity views so if it is not provided it gets set to an empty string
    setTitlePrefix(prefix)
    
    if (title) setTitle(title)
    if (suffix) setTitleSuffix(suffix)
  }, [prefix, title, suffix])

  return <></>
}

export default TerminalDocumentTitle
