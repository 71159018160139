import { Grid } from '@visx/xychart'

interface HorizontalLinesProps {
  numTicks?: number
  stroke?: string
}

const HorizontalLines = ({
  numTicks = 5,
  stroke = '#6B7280'
}: HorizontalLinesProps) => {
  return (
    <Grid
      rows
      columns={false}
      numTicks={numTicks}
      stroke={stroke}
      strokeWidth={1}
    />
  )
}

export default HorizontalLines
