import AddIcon from '@mui/icons-material/Add'
import clsx from 'clsx'
import { UIEvent, useState } from 'react'

import { Button, Col, Row, Text } from '@/components/atoms'
import { QueueCard } from '@/features/gate'
import { LaneDirection } from '@/features/gate/enums'
import { GateQueueEvent, Lane } from '@/features/gate/types'
import { getLaneDirectionName } from '@/features/gate/utils'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import GateTransactions from '@/assets/icons/gate_transactions.svg?react'
import { BaseEmptyState } from '@/components/organisms'
import useViewportSize from '@/hooks/useViewportSize'
import { useNavigator } from '@/router'
import { useParams } from 'react-router-dom'
import styles from './GateQueue.module.scss'

interface Props {
  type: LaneDirection
  lane: Lane
  isActionAllowed: boolean
  items: GateQueueEvent[]
  onSwapLane: (item: GateQueueEvent, direction: LaneDirection) => void
  totalCount: number
  openModal: (
    item: GateQueueEvent | undefined,
    lane: Lane,
    deleteItem?: boolean
  ) => void
}

const GateQueue = (props: Props) => {
  const {
    type,
    lane,
    items,
    totalCount,
    isActionAllowed,
    onSwapLane,
    openModal
  } = props

  const { site_id } = useParams()

  const [showBorder, setShowBorder] = useState<boolean>(false)

  const navigator = useNavigator(site_id)

  const isCheckIn = type === LaneDirection.Arriving
  const emptyQueueDescription = `Power units ready to be checked-${
    isCheckIn ? 'in' : 'out'
  } will appear here.`

  const { isSmallView } = useViewportSize()
  const tabsMode = isSmallView

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.target as HTMLElement

    const scrolled = scrollTop > 0

    if (scrolled !== showBorder) {
      setShowBorder(scrolled)
    }
  }

  return (
    <div
      className={clsx(styles.queueWrapper, tabsMode && styles.small)}
      onScroll={handleScroll}
    >
      {!tabsMode && (
        <Row
          items="center"
          gap={20}
          justify="between"
          className={clsx(styles.queueHeader, showBorder && styles.border)}
        >
          <Row items="center" gap={10}>
            <Text
              type={TextTypes.TEXT_MD}
              color={Color.gray700}
              weight={FontWeight.SEMIBOLD}
            >
              {getLaneDirectionName(type)}
            </Text>

            {!!totalCount && (
              <div className={styles.queueLength}>
                <Text
                  type={TextTypes.TEXT_MD}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.gray700}
                >
                  {totalCount}
                </Text>
              </div>
            )}
          </Row>

          {isActionAllowed && (
            <Button
              square
              type="outlined"
              onClick={() => {
                if (isCheckIn) navigator.toCheckIn()
                else navigator.toCheckOut()
              }}
            >
              <AddIcon />
            </Button>
          )}
        </Row>
      )}

      {items.length ? (
        <Col gap={16} items="center" className={styles.queueItems}>
          {items.map((item) => (
            <QueueCard
              key={item.id}
              type={type}
              item={item}
              onSwapLane={onSwapLane}
              isActionAllowed={isActionAllowed}
              onCheckInOut={(i) => openModal(i, lane)}
              onDelete={() => openModal(item as GateQueueEvent, lane, true)}
            />
          ))}
        </Col>
      ) : (
        <BaseEmptyState
          primaryText="Nothing in the queue"
          descriptionText={emptyQueueDescription}
          Icon={<GateTransactions />}
          className={styles.emptyState}
        />
      )}
    </div>
  )
}

export default GateQueue
