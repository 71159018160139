import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { Fade } from '@mui/material'
import { capitalize } from 'lodash'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Col, DarkButton, Logo, Row, Text } from '@/components/atoms'
import { useMobileNavigationContext } from '@/components/contexts'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { FontWeight, SidebarScreens, TextTypes } from '@/types/enums/ui'

import useNavigateHome from '@/hooks/useNavigateHome'
import useViewportSize from '@/hooks/useViewportSize'
import { getPageTitle } from '@/utils/helpers'
import clsx from 'clsx'
import styles from './NavigationHeader.module.scss'

interface IProps {
  collapsed: boolean
  screen: SidebarScreens
  setScreen: Dispatch<SetStateAction<SidebarScreens>>
}

const NavigationHeader: FC<IProps> = (props) => {
  const { collapsed, screen, setScreen } = props

  const location = useLocation()
  const { toggleMobileNavigation } = useMobileNavigationContext()

  const { org, selectedGate, selectedPortal } = useStore((store) => store.user)

  const { isMediumView } = useViewportSize()
  const portalPickerIsNotVisible = screen !== SidebarScreens.MenuItems

  const detailsOptions = useMemo(
    () => ({
      [SidebarScreens.AccountItems]: {
        title: 'Account',
        buttonIcon: <ArrowBackOutlinedIcon />,
        onButtonClick: () => setScreen(SidebarScreens.MenuItems)
      },

      [SidebarScreens.MenuItems]: {
        title: getPageTitle(
          location.pathname,
          selectedPortal?.id || '',
          selectedGate?.id || ''
        ),
        buttonIcon: <CloseIcon />,
        onButtonClick: () => toggleMobileNavigation()
      }
    }),
    [location.pathname, screen, toggleMobileNavigation]
  )

  const { title, buttonIcon, onButtonClick } = detailsOptions[screen]
  const navigateHome = useNavigateHome()

  return (
    <Row
      items="center"
      gap={12}
      className={clsx(
        styles.navHeader,
        portalPickerIsNotVisible && styles.borderBottom
      )}
    >
      {isMediumView ? (
        <DarkButton
          id="close-menu"
          className={styles.closeMenuButton}
          onClick={onButtonClick}
        >
          {buttonIcon}
        </DarkButton>
      ) : (
        <Logo
          className={styles.logo}
          onClick={() => navigateHome(selectedPortal?.id)}
        />
      )}

      <Fade in={!collapsed} timeout={500} appear={false}>
        <div>
          <Col gap={3}>
            <Text
              type={isMediumView ? TextTypes.TEXT_XL : TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray0}
              className={styles.brandName}
            >
              {isMediumView ? title : 'Terminal'}
            </Text>

            {!isMediumView && (
              <Text
                type={TextTypes.TEXT_XS}
                color={Color.gray500}
                className={styles.brandName}
              >
                {capitalize(org?.organization_display_name)}
              </Text>
            )}
          </Col>
        </div>
      </Fade>
    </Row>
  )
}

export default NavigationHeader
