import React, { createContext, useContext, useEffect, useState } from 'react'

interface DocumentTitleContextProps {
  title: string
  setTitle: (title?: string) => void
  titlePrefix: string
  setTitlePrefix: (prefix?: string) => void
  titleSuffix: string
  setTitleSuffix: (suffix?: string) => void
  prefixSuffixSeparator: string
  setPrefixSuffixSeparator: (separator?: string) => void
}

const DocumentTitleContext = createContext<
  DocumentTitleContextProps | undefined
>(undefined)

interface DocumentTitleProviderProps {
  children: React.ReactNode
  initialTitle?: string
  initialPrefix?: string
  initialSuffix?: string
  initialPrefixSuffixSeparator?: string
}

export function DocumentTitleProvider({
  children,
  initialTitle,
  initialPrefix,
  initialSuffix,
  initialPrefixSuffixSeparator
}: DocumentTitleProviderProps) {
  const [title, _setTitle] = useState<string>(initialTitle ?? '')
  const [titlePrefix, _setTitlePrefix] = useState<string>(initialPrefix ?? '')
  const [titleSuffix, _setTitleSuffix] = useState<string>(initialSuffix ?? '')
  const [prefixSuffixSeparator, _setPrefixSuffixSeparator] = useState<string>(
    initialPrefixSuffixSeparator ?? ' | '
  )

  const setTitle = (val?: string) => _setTitle(val ?? '')
  const setTitlePrefix = (val?: string) => _setTitlePrefix(val ?? '')
  const setTitleSuffix = (val?: string) => _setTitleSuffix(val ?? '')
  const setPrefixSuffixSeparator = (val?: string) =>
    _setPrefixSuffixSeparator(val ?? ' | ')

  useEffect(() => {
    const parts: string[] = []
    if (titlePrefix) parts.push(titlePrefix)
    if (title) parts.push(title)
    if (titleSuffix) parts.push(titleSuffix)
    document.title = parts.join(prefixSuffixSeparator)
  }, [title, titlePrefix, titleSuffix, prefixSuffixSeparator])

  return (
    <DocumentTitleContext.Provider
      value={{
        title,
        setTitle,
        titlePrefix,
        setTitlePrefix,
        titleSuffix,
        setTitleSuffix,
        prefixSuffixSeparator,
        setPrefixSuffixSeparator
      }}
    >
      {children}
    </DocumentTitleContext.Provider>
  )
}

export function useDocumentTitle() {
  const context = useContext(DocumentTitleContext)
  if (!context) {
    throw new Error(
      'useDocumentTitle must be used within a DocumentTitleProvider'
    )
  }
  return context
}
