import { createClient } from '@sanity/client'

const PROJECT_ID = 'kmab2sb7'
const DATASET = 'yardstar-appwide-banner'

export const sanityClient = createClient({
  projectId: PROJECT_ID,
  dataset: DATASET,
  useCdn: true,
  apiVersion: '2022-03-08'
})
