import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined'

import React, { FC, useState, MouseEvent } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  DriverDetailsFormSection,
  FormCol,
  FormRow,
  PowerUnitFormSection
} from '@/features/forms/components'
import { FORM_IDS } from '@/features/forms/constants'
import { FormSectionType } from '@/features/forms/enums'
import { ToggleDetails } from '@/features/gate'
import { GateTransactionSchemaType } from '@/features/gate/utils'
import {
  AppointmentTypes,
  FuelTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'
import { GateTransaction } from '@/__generated__/graphql'
import { Button, Chip } from '@/components/atoms'

interface IProps {
  siteId: string
  isManual: boolean
  isPresent: boolean
  onAdd: (type: FormSectionType) => void
  onRemove?: () => void
}

const CheckOutPowerUnit: FC<IProps> = (props) => {
  const { siteId, isPresent, isManual, onRemove, onAdd } = props

  const { trigger, reset, watch } = useFormContext<GateTransactionSchemaType>()

  const [selectedEvent, setSelectedEvent] = useState<
    GateTransaction | undefined
  >(undefined)
  const [isDriverEditable, setIsDriverEditable] = useState(false)

  const isVisitor =
    watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE) === AppointmentTypes.Visitor

  const populatePowerUnitFields = (
    selectedEvent: GateTransaction | undefined
  ) => {
    setSelectedEvent(selectedEvent)

    const { metadata } = selectedEvent || {}
    const {
      appointmentType,

      powerUnitType,
      powerUnitOwnerId,
      powerUnitLicensePlateNumber,
      powerUnitLicensePlateState,
      powerUnitCarrierUsdot,
      powerUnitCarrierName,
      powerUnitWeightClass,
      powerUnitFuelType,
      powerUnitVin,
      accountName,

      driverFirstName,
      driverLastName,
      driverLicenseNumber,
      driverLicenseState,
      driverPhoneNumber
    } = metadata || {}

    reset(
      (prev) => ({
        ...prev,

        [FORM_IDS.GENERAL.APPOINTMENT_TYPE]: Object.values(
          AppointmentTypes
        ).includes(appointmentType as AppointmentTypes)
          ? (appointmentType as AppointmentTypes)
          : AppointmentTypes.Scheduled,

        [FORM_IDS.POWER_UNIT.TYPE]: powerUnitType as PowerUnitTypes,
        [FORM_IDS.POWER_UNIT.OWNER_ID]: powerUnitOwnerId || '',
        [FORM_IDS.POWER_UNIT.LPN]: powerUnitLicensePlateNumber || '',
        [FORM_IDS.POWER_UNIT.LPN_STATE]: powerUnitLicensePlateState || '',
        [FORM_IDS.POWER_UNIT.USDOT]: powerUnitCarrierUsdot || '',
        [FORM_IDS.POWER_UNIT.CARRIER]: powerUnitCarrierName || '',
        [FORM_IDS.POWER_UNIT.WEIGHT_CLASS]:
          powerUnitWeightClass as WeightClasses,
        [FORM_IDS.POWER_UNIT.FUEL_TYPE]: powerUnitFuelType as FuelTypes,
        [FORM_IDS.POWER_UNIT.VIN]: powerUnitVin || '',
        [FORM_IDS.POWER_UNIT.ACCOUNT]: accountName || [],

        [FORM_IDS.DRIVER_DETAILS.FIRST_NAME]: driverFirstName || '',
        [FORM_IDS.DRIVER_DETAILS.LAST_NAME]: driverLastName || '',
        [FORM_IDS.DRIVER_DETAILS.DRIVER_ID]: driverLicenseNumber || '',
        [FORM_IDS.DRIVER_DETAILS.LICENSE_STATE]: driverLicenseState || '',
        [FORM_IDS.DRIVER_DETAILS.PHONE]: driverPhoneNumber || ''
      }),
      { keepDirty: true, keepSubmitCount: true }
    )

    // Revalidate fields which are not read-only to make sure errors are cleared
    // after selecting a new value
    trigger([
      FORM_IDS.POWER_UNIT.OWNER_ID as keyof GateTransactionSchemaType,
      FORM_IDS.POWER_UNIT.LPN as keyof GateTransactionSchemaType
    ])
  }

  const onChangeDriverClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    const {
      driverFirstName = '',
      driverLastName = '',
      driverLicenseNumber = '',
      driverLicenseState = '',
      driverPhoneNumber = ''
    } = selectedEvent?.metadata || {}

    // When user decides to change driver details we reset all driver fields to empty values,
    // when user wants to reset to original we populate driver fields from selected transaction
    reset(
      (prev) => ({
        ...prev,
        [FORM_IDS.DRIVER_DETAILS.FIRST_NAME]: isDriverEditable
          ? driverFirstName
          : '',
        [FORM_IDS.DRIVER_DETAILS.LAST_NAME]: isDriverEditable
          ? driverLastName
          : '',
        [FORM_IDS.DRIVER_DETAILS.DRIVER_ID]: isDriverEditable
          ? driverLicenseNumber
          : '',
        [FORM_IDS.DRIVER_DETAILS.LICENSE_STATE]: isDriverEditable
          ? driverLicenseState
          : '',
        [FORM_IDS.DRIVER_DETAILS.PHONE]: isDriverEditable
          ? driverPhoneNumber
          : ''
      }),
      { keepDirty: true, keepSubmitCount: true }
    )

    setIsDriverEditable(!isDriverEditable)
  }

  const DriverDetails = (
    <DriverDetailsFormSection
      title={isManual ? 'Driver' : 'Driver Details'}
      withoutWrapper={isManual}
      readOnly={!isDriverEditable}
      Action={
        !!selectedEvent && (
          <Button
            small
            type="outlined"
            startIcon={
              isDriverEditable ? (
                <UndoOutlinedIcon />
              ) : (
                <SupervisorAccountOutlinedIcon />
              )
            }
            onClick={onChangeDriverClick}
          >
            {isDriverEditable ? 'Reset to Original' : 'Change Driver'}
          </Button>
        )
      }
    />
  )

  return (
    <>
      <PowerUnitFormSection
        isPresent={isPresent}
        onAdd={onAdd}
        onRemove={onRemove}
        TitleSuffix={isVisitor && <Chip label="Visitor" type="default" />}
      >
        <FormCol>
          <FormRow columnOnMobile>
            <FormRow>
              <PowerUnitFormSection.LicensePlateNumber
                autoComplete
                siteId={siteId}
                selectedEventId={selectedEvent?.id}
                onOptionSelect={populatePowerUnitFields}
              />
              <PowerUnitFormSection.LicensePlateState readOnly />
            </FormRow>

            <PowerUnitFormSection.VinNumber readOnly allowApiCalls={false} />
          </FormRow>

          <FormRow>
            <PowerUnitFormSection.UsDOT readOnly />
            <PowerUnitFormSection.OperatingCarrier readOnly />
          </FormRow>

          <ToggleDetails>
            <FormCol>
              <FormRow>
                <PowerUnitFormSection.Type readOnly />
                <PowerUnitFormSection.OwnerId readOnly />
              </FormRow>

              <FormRow>
                <PowerUnitFormSection.WeightClass readOnly />
                <PowerUnitFormSection.FuelType readOnly />
              </FormRow>

              <FormRow>
                <PowerUnitFormSection.Account readOnly />
              </FormRow>
            </FormCol>
          </ToggleDetails>

          {isManual && DriverDetails}
        </FormCol>
      </PowerUnitFormSection>

      {!isManual && DriverDetails}
    </>
  )
}

export default CheckOutPowerUnit
