import { useCallback, useEffect, useMemo } from 'react'
import { matchPath, Outlet, useParams } from 'react-router-dom'

import { Tabs } from '@/components/molecules'
import { FullSpaceTemplate, WithHeaderTemplate } from '@/components/templates'
import { SitePortalPermissions } from '@/features/auth/types'
import { GateDropdown } from '@/features/gate'
import { ROUTES, useNavigator } from '@/router'
import { useStore } from '@/store'
import { ITab } from '@/types/interfaces/ui'

import useViewportSize from '@/hooks/useViewportSize'
import { GateTab } from '../types'
import { GateQueueTemplate } from './GateQueueTemplate'
import { GateTransactionTemplate } from './GateTransactionTemplate'
import usePathMatch from '@/hooks/usePathMatch'

export const GateTemplate = () => {
  const { selectedPortal } = useStore((store) => store.user)
  const permissions = selectedPortal?.permissions as
    | SitePortalPermissions
    | undefined

  const hasGateQueueAccess = !!permissions?.gate_queue
  const hasGateTransactionAccess = !!permissions?.gate_transaction

  const { site_id } = useParams()
  const navigator = useNavigator(site_id)

  const { isSmallView } = useViewportSize()

  const activeTab = useMemo(() => {
    const queueMatch = matchPath(ROUTES.QUEUES, location.pathname)

    const checkInMatch = usePathMatch([ROUTES.CHECK_IN, ROUTES.MANUAL_CHECK_IN])
    const checkOutMatch = usePathMatch([
      ROUTES.CHECK_OUT,
      ROUTES.MANUAL_CHECK_OUT
    ])
    const transactionMatch = usePathMatch([
      ROUTES.TRANSACTION,
      ROUTES.TRANSACTIONS
    ])

    if (checkInMatch || checkOutMatch) return GateTab.Queues
    if (!!queueMatch) return GateTab.Queues
    if (!!transactionMatch) return GateTab.Transactions

    return '' as GateTab
  }, [location.pathname])

  const setTab = useCallback(
    (tab: GateTab | undefined) => {
      if (tab === GateTab.Queues) {
        navigator.toGateQueue()
      } else if (tab === GateTab.Transactions) {
        navigator.toGateTransactions()
      } else {
        console.warn('Invalid tab selected')
      }
    },
    [navigator]
  )

  // On initial page view, check permissions for validity of route
  useEffect(() => {
    // Automatic redirect will happen to first available page from MainTemplate if user has no permissions
    if (!hasGateQueueAccess && !hasGateTransactionAccess) return

    const existingTabSelected = Object.values(GateTab).includes(activeTab)

    if (existingTabSelected) {
      // On queue but route not authorized
      if (!hasGateQueueAccess) setTab(GateTab.Transactions)
      return
    }

    setTab(hasGateQueueAccess ? GateTab.Queues : GateTab.Transactions)
  }, [activeTab, permissions])

  const authorizedTabs: ITab<GateTab>[] = useMemo(() => {
    if (!permissions) return []

    const tabs: ITab<GateTab>[] = []

    if (hasGateQueueAccess) {
      tabs.push({
        id: GateTab.Queues,
        title: isSmallView ? 'Gate Queues' : 'Queues',
        Component: <GateQueueTemplate />
      })
    }

    if (hasGateTransactionAccess) {
      tabs.push({
        id: GateTab.Transactions,
        title: isSmallView ? 'Gate Transactions' : 'Transactions',
        Component: <GateTransactionTemplate />
      })
    }

    return tabs
  }, [permissions, isSmallView])

  return (
    <WithHeaderTemplate
      title={hasGateQueueAccess ? 'Gate' : 'Gate Transactions'}
      dropdownTabs={isSmallView ? authorizedTabs : []}
      showBottomBorder={isSmallView}
      Action={activeTab === GateTab.Queues ? <GateDropdown /> : undefined}
    >
      {isSmallView ? (
        <Outlet />
      ) : (
        <>
          {hasGateQueueAccess ? (
            <FullSpaceTemplate>
              <Tabs
                active={activeTab}
                setActive={setTab}
                tabs={authorizedTabs}
              />
            </FullSpaceTemplate>
          ) : (
            <GateTransactionTemplate />
          )}
        </>
      )}
    </WithHeaderTemplate>
  )
}
