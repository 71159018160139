import { Col, Row, Text } from '@/components/atoms'
import { TextTypes } from '@/types/enums/ui'
import LegendLine from '../Legend/LegendLine'

import clsx from 'clsx'
import styles from './TimeseriesTooltip.module.scss'

interface TimeseriesTooltipLabel {
  title: string
  value: string
  color: string
}

interface TimeseriesTooltipProps {
  title: string
  labels: TimeseriesTooltipLabel[]
}

const TimeseriesTooltip = ({ title, labels }: TimeseriesTooltipProps) => {
  return (
    <Col className={styles.timeseriesTooltipContainer}>
      <Text type={TextTypes.TEXT_SM}>{title}</Text>
      <Col className={styles.timeseriesInformationLabelsContainer}>
        {labels.map((label, index) => {
          return (
            <Row
              className={clsx(styles.timeseriesInformationLabel, {
                [styles.withBorder]: index !== labels.length - 1
              })}
              key={index}
            >
              <Row>
                <Col className={styles.legendLineContainer}>
                  <LegendLine lineColor={label.color} />
                </Col>
                <Text type={TextTypes.TEXT_XS}>{label.title}</Text>
              </Row>
              <Row>
                <Text
                  className={styles.timeseriesValueLabel}
                  type={TextTypes.TEXT_SM}
                >
                  {label.value}
                </Text>
              </Row>
            </Row>
          )
        })}
      </Col>
    </Col>
  )
}

export default TimeseriesTooltip
