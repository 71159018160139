import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import { Row } from '@/components/atoms'

import styles from './FormRow.module.scss'

interface Props {
  autoWrap?: boolean
  className?: string
  columnOnMobile?: boolean
}

const FormRow: FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, autoWrap, columnOnMobile } = props

  return (
    <Row
      gap={16}
      className={clsx(
        styles.formRow,
        autoWrap && styles.autoWrap,
        columnOnMobile && styles.columnOnMobile,
        className
      )}
    >
      {children}
    </Row>
  )
}

export default FormRow
