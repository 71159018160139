import { StringOperator } from '@/__generated__/graphql'
import { OLDEST_AVAILABLE_DATE } from '@/constants'
import { useStore } from '@/store'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { TimeUnit } from '@/types/enums/ui'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import {
  GetDriversOnsiteQuery,
  GetEnterpriseDriversOnsiteQuery
} from '../api/queries'

interface Props {
  query: any
  enterpriseMode?: boolean
  filters: any
}

const useGateDriverRollups = ({
  query,
  enterpriseMode = false,
  filters
}: Props) => {
  const { selectedPortal } = useStore((store) => store.user)
  const site_id = selectedPortal?.id || ''

  const {
    appointmentType: _ignore,
    ...remainingFiltersWithoutAppointmentType
  } = filters

  const appointmentTypes = filters?.appointmentType?.values || []

  const skipVisitors =
    !!appointmentTypes &&
    appointmentTypes.length > 0 &&
    !appointmentTypes.includes(AppointmentTypes.Visitor)

  const dataModeQuery = enterpriseMode
    ? GetEnterpriseDriversOnsiteQuery
    : GetDriversOnsiteQuery

  const { loading: loadingVisitors, data: visitorData } = useQuery(
    dataModeQuery,
    {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutAppointmentType,
            appointmentType: {
              operator: StringOperator.Equals,
              value: AppointmentTypes.Visitor
            }
          }
        }
      },
      skip: skipVisitors
    }
  )

  const HOURS_AGO = 2
  const twoHoursAgo = useMemo(
    () => dayjs().subtract(HOURS_AGO, TimeUnit.Hour).toISOString(),
    []
  )

  const { loading: loadingOnSiteOverTwoHours, data: onSiteOverTwoHoursData } =
    useQuery(dataModeQuery, {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...filters
          },
          dateRange: {
            startDate: dayjs(OLDEST_AVAILABLE_DATE)?.toISOString(),
            endDate: twoHoursAgo
          }
        }
      },
      // No need to ever skip here since the other roll up is number
      //  of visitors and only one dwell at a time makes logical sense
      skip: false
    })

  const visitorDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (visitorData as any)?.listEnterpriseDriversOnSites?.pagination
          ?.totalItemsCount || 0
      )
    }

    return visitorData?.listDriversOnSite?.pagination?.totalItemsCount || 0
  }, [visitorData, loadingVisitors, enterpriseMode, filters])

  const onSiteOverTwoHoursDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (onSiteOverTwoHoursData as any)?.listEnterpriseDriversOnSites?.pagination?.totalItemsCount || 0
    }

    return (
      onSiteOverTwoHoursData?.listDriversOnSite?.pagination?.totalItemsCount ||
      0
    )
  }, [
    onSiteOverTwoHoursData,
    loadingOnSiteOverTwoHours,
    enterpriseMode,
    filters
  ])

  return {
    loadingVisitors,
    visitorDataCount,
    loadingOnSiteOverTwoHours,
    onSiteOverTwoHoursDataCount
  }
}

export default useGateDriverRollups
