import { GateWithLanes } from '@/features/gate/types'
import { LocalStorageKeys } from '@/types/enums/storage'

const getInitialGate = (gates: GateWithLanes[]) => {
  const lastSelectedGateId = localStorage.getItem(
    LocalStorageKeys.LastSelectedGate
  )
  const lastSelectedGate = gates.find((gate) => gate.id === lastSelectedGateId)

  return lastSelectedGate || gates[0]
}

export default getInitialGate
