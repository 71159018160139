import { buildChartTheme } from '@visx/xychart'

const DEFAULT_HORIZONTAL_LINES_COLOR = '#E5E7EB'

export const generateTimeseriesChartTheme = (
  colors: string[],
  backgroundColor = 'white',
  horizontalLinesColor = DEFAULT_HORIZONTAL_LINES_COLOR,
  tickLength = 6
) => {
  return buildChartTheme({
    backgroundColor: backgroundColor,
    colors: colors,
    gridColor: horizontalLinesColor,
    gridColorDark: horizontalLinesColor,
    tickLength: tickLength
  })
}
