const withPrefix = (
  value: string,
  prefix: string | undefined,
  separator = '_'
): string => {
  if (!prefix) return value

  return `${prefix}${separator}${value}`
}

export default withPrefix
