import { PermissionResourceType } from '@/features/auth/enums'
import { ISite } from '@/features/user-management/api/types'
import { PortalTypes } from '@/types/enums/global'
import { ApiResponse } from '@/types/interfaces/api'
import { GateWithLanes } from '@/features/gate/types'

export interface UserDetails {
  user_id: string
  first_name: string
  last_name: string
  nickname: string
  name: string
  email: string
  email_verified: boolean
  picture: string
}

export interface OrganizationDetails {
  organization_id: string
  organization_name: string
  organization_subdomain: string
  organization_display_name: string
}

export enum SitePortalPermissionKeys {
  GATE_QUEUE = 'gate_queue',
  GATE_TRANSACTION = 'gate_transaction',
  ONSITE_DRIVER = 'onsite_driver',
  ONSITE_CARGO_ASSET = 'onsite_cargo_asset',
  ISR = 'isr'
}

export interface SitePortalPermissions {
  gate_queue: boolean
  gate_transaction: boolean
  onsite_driver: boolean
  onsite_cargo_asset: boolean
  isr: boolean
}

export interface EnterprisePortalPermissions {
  users: boolean
}

export interface SitePortalListItem {
  type: PortalTypes.Site
  permissions: SitePortalPermissions | undefined
}

export interface EnterprisePortalListItem {
  type: PortalTypes.Enterprise
  permissions: EnterprisePortalPermissions
}

export type UserPortalsListItem = {
  id: string
  name: string
} & (SitePortalListItem | EnterprisePortalListItem)

export interface UserStore {
  me: UserDetails | undefined
  selectedPortal: UserPortalsListItem | undefined
  gates: GateWithLanes[] | undefined
  selectedGate: GateWithLanes | undefined
  authToken: string | undefined
  org: OrganizationDetails | undefined
  portals: UserPortalsListItem[]
}

export interface UpdatePortalsPayload {
  sites: ISite[]
  enterpriseEnabled: boolean
  portalIdFromUrl?: string
}

export interface Jwt {
  user_id: string
  first_name: string
  last_name: string
  nickname: string
  name: string
  email: string
  email_verified: boolean
  picture: string
  organization_id: string
  organization_name: string
  organization_subdomain: string
  organization_display_name: string
  iss: string
  sub: string
  aud: string
  iat: number
  exp: number
  scope: string
  org_id: string
  azp: string
}

export type PermissionResource = `${PermissionResourceType}:${string}`

export interface CheckPermissionRequest {
  resource: PermissionResource
  relation: string
}

export type CheckPermissionResponse = ApiResponse<{
  has_permission: boolean
}>
