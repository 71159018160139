import { Col, Row, Text } from '@/components/atoms'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { LegendItem } from '@visx/legend'
import LegendLine from './LegendLine'

import styles from './LegendLabel.module.scss'

export type LegendType = 'line' | 'pie'

interface CustomLegendLabelProps {
  lineColor?: string
  index: number
  labelText: string
  currentValue?: string | number
  legendType?: LegendType
}

const CustomLegendLabel = ({
  lineColor,
  index,
  labelText,
  currentValue,
  legendType = 'line'
}: CustomLegendLabelProps) => {
  const renderLegendSymbol = (legendType: LegendType, lineColor?: string) => {
    switch (legendType) {
      case 'pie':
        return (
          <LegendLine lineColor={lineColor} width={8} height={8} radius={2} />
        )
      case 'line':
      default:
        return <LegendLine lineColor={lineColor} />
    }
  }

  return (
    <LegendItem key={`legend-${index}`}>
      <Col className={styles.legendItemColumn}>
        <Row items="center">
          {renderLegendSymbol(legendType, lineColor)}
          <Text
            className={styles.labelText}
            type={TextTypes.TEXT_XS}
            weight={FontWeight.REGULAR}
          >
            {labelText}
          </Text>
        </Row>
        <Row className="tw-ml-16">
          <Text
            className={styles.valueLabelText}
            type={TextTypes.TEXT_LG}
            weight={FontWeight.BOLD}
          >
            {currentValue ?? '-'}
          </Text>
        </Row>
      </Col>
    </LegendItem>
  )
}

export default CustomLegendLabel
