import { Col, Row, Text } from '@/components/atoms'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { Group } from '@visx/group'
import { Pie } from '@visx/shape'

import { useMemo } from 'react'
import { ROLLUP_WIDGET_COLORS } from '../Shared/Colors/RollupColors'
import StatisticHeader from '../Shared/Headers/StatisticHeader'
import NoDataAvailable from '../Shared/Unavailable/NoDataAvailable'
import CustomLegendLabel from '../Timeseries/Legend/LegendLabel'
import NoDataPie from './NoDataPie'
import styles from './PieChart.module.scss'

interface PieChartSection {
  title: string
  value: number
}

interface PieChartProps {
  headerText: string
  subHeaderText: string
  data?: PieChartSection[]
  totalLabel: string
  sectionSpacing?: number
  length?: number
  innerRadiusScalar?: number
  colors?: string[]
}

const DEFAULT_LENGTH = 200

const PieChart = ({
  headerText,
  subHeaderText,
  data = [],
  totalLabel,
  sectionSpacing = 0.01,
  length = DEFAULT_LENGTH,
  innerRadiusScalar = 0.65,
  colors = ROLLUP_WIDGET_COLORS
}: PieChartProps) => {
  const outerRadius = length / 2
  const innerRadius = outerRadius * innerRadiusScalar
  const isDonut = innerRadiusScalar !== 0.0

  const totalValue = useMemo(() => {
    return data.reduce((sum, { value }) => sum + value, 0)
  }, [data])

  const getColorForIndex = (index: number) => {
    return colors[index % colors.length]
  }

  const transformLabel = (value: number) => {
    return value === 0 ? '-' : value
  }

  const donutLabels = (
    <foreignObject
      x={-outerRadius}
      y={-outerRadius / Math.PI}
      width={outerRadius * 2}
      height={outerRadius * 2}
      className={styles.donutCenterLabels}
    >
      <Text
        type={TextTypes.DISPLAY_SM}
        weight={FontWeight.BOLD}
        className={styles.pieChartTotalValueLabel}
      >
        {transformLabel(totalValue)}
      </Text>
      <Text
        type={TextTypes.TEXT_SM}
        weight={FontWeight.REGULAR}
        className={styles.pieChartTotalLabel}
      >
        {totalLabel}
      </Text>
    </foreignObject>
  )

  const EmptyDataPie = (
    <NoDataPie
      outerRadius={outerRadius}
      innerRadius={innerRadius}
      sectionSpacing={sectionSpacing}
    />
  )

  const dataPiePadAngle = useMemo(() => {
    const numberOfZeroScalars = data.filter(
      (section) => section.value === 0
    ).length
    const allButOneZero = numberOfZeroScalars === data.length - 1
    return allButOneZero ? 0 : sectionSpacing
  }, [data])

  const DataPie = (
    <Pie
      data={data}
      pieValue={(section) => section.value}
      outerRadius={outerRadius}
      innerRadius={innerRadius}
      padAngle={dataPiePadAngle}
    >
      {(pie) =>
        pie.arcs.map((arc, i) => (
          <g key={`arc-${i}`}>
            <path d={pie.path(arc) ?? ''} fill={getColorForIndex(i)} />
          </g>
        ))
      }
    </Pie>
  )

  const PieChart = (
    <Row className={styles.pieChartContainer}>
      <svg width={length} height={length}>
        <Group top={length / 2} left={length / 2}>
          {totalValue === 0 ? EmptyDataPie : DataPie}
          {isDonut && donutLabels}
        </Group>
      </svg>
    </Row>
  )

  const PieChartLegend = (
    <Col className={styles.labelsColumn}>
      {data.map((section, index) => (
        <CustomLegendLabel
          key={section.title}
          index={index}
          labelText={section.title}
          currentValue={section.value}
          lineColor={getColorForIndex(index)}
          legendType="pie"
        />
      ))}
    </Col>
  )

  return (
    <div className={styles.container}>
      <StatisticHeader headerText={headerText} subHeaderText={subHeaderText} />
      <Row className={styles.pieChartAndLegendRow}>
        {PieChart}
        {PieChartLegend}
      </Row>
      {totalValue === 0 && (
        <Row className={styles.overlay}>
          <NoDataAvailable />
        </Row>
      )}
    </div>
  )
}

export default PieChart
