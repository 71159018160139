import { TimeseriesDataPoint } from './TimeseriesDataPoint'

const TIME_SERIES_MONTH_SPAN = 3

export const noDataTimeseriesScalarConfig = {
  x: {
    type: 'linear',
    domain: [
      new Date(
        new Date().setMonth(new Date().getMonth() - TIME_SERIES_MONTH_SPAN)
      ).getTime(),
      new Date().getTime()
    ]
  },
  y: {
    type: 'linear',
    domain: [0, 100]
  }
}

export const timeseriesScalarConfig = {
  x: {
    type: 'band' as const,
    paddingInner: 1.0
  },
  y: {
    type: 'linear' as const,
    nice: true
  }
}

export const timeseriesScalarAccessors = {
  xAccessor: (d: TimeseriesDataPoint) => d.x,
  yAccessor: (d: TimeseriesDataPoint) => d.y
}
