import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  cargoAssetTypesFriendlyNames,
  loadTypesFriendlyNames
} from '@/utils/mappers'
import {
  appointmentTypeToBoolean,
  arrayToString,
  milliSecondsToDurationTableConverter,
  sealMatchesToBoolean,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'
import friendlyName from '@/utils/table/converters/friendlyName'
import {
  GateTransaction,
  OrderByDomainEventColumn
} from '@/__generated__/graphql'
import { UserPortalsListItem } from '@/features/auth/types'
import { orFallback } from '@/utils/helpers'

dayjs.extend(duration)

export const CARGO_ASSETS_COLUMNS_VERSION = '1.0.0'

export const cargoAssetsColumns = (
  isEnterpriseMode: boolean,
  sites: UserPortalsListItem[]
): ITableColumn[] => [
  {
    id: 'eventTime',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true,
    sortId: OrderByDomainEventColumn.EventTime,
    defaultVisible: true
  },
  ...(isEnterpriseMode
    ? [
        {
          id: 'site',
          title: 'Site',
          type: TableColumnType.Text,
          converter: (row: GateTransaction) =>
            orFallback(sites.find((site) => site.id === row.siteId)?.name),
          defaultVisible: true
        }
      ]
    : []),
  {
    id: 'metadata.appointmentType',
    title: 'Visitor',
    type: TableColumnType.Text,
    converter: appointmentTypeToBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.AppointmentType
  },
  {
    id: 'metadata.cargoAssetOwnerId',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetOwnerId,
    defaultVisible: true
  },
  {
    id: 'metadata.chassisId',
    title: 'Chassis ID',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.ChassisId
  },
  {
    id: 'metadata.cargoAssetLicensePlateNumber',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetLicensePlateNumber
  },
  {
    id: 'metadata.cargoAssetLicensePlateState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetLicensePlateState
  },
  {
    id: 'metadata.cargoAssetCarrierName',
    title: 'Asset Carrier',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetCarrierName
  },
  {
    id: 'metadata.powerUnitCarrierName',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.PowerUnitCarrierName
  },
  {
    id: 'metadata.cargoAssetAssetType',
    title: 'Asset Type',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: cargoAssetTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.CargoAssetAssetType,
    defaultVisible: true
  },
  {
    id: 'metadata.loadStatus',
    title: 'Arriving Load Status',
    type: TableColumnType.Text,
    converter: friendlyName,
    friendlyNameMapper: loadTypesFriendlyNames,
    sortable: true,
    sortId: OrderByDomainEventColumn.LoadStatus,
    defaultVisible: true
  },
  {
    id: 'metadata.shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true,
    sortId: OrderByDomainEventColumn.ShipmentNumber
  },
  {
    id: 'metadata.accountName',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByDomainEventColumn.AccountName
  },
  {
    id: 'metadata.sealNumbers',
    title: 'Seal #',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true,
    sortId: OrderByDomainEventColumn.SealNumbers
  },
  {
    id: 'metadata.sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: sealMatchesToBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.SealMatchPw
  },
  {
    id: 'dwell',
    title: 'Site Dwell',
    type: TableColumnType.Text,
    converter: (row: GateTransaction) =>
      milliSecondsToDurationTableConverter(
        { dwell: dayjs().diff(row.eventTime) },
        'dwell'
      ),
    defaultVisible: true
  },
  {
    id: 'metadata.mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true,
    sortId: OrderByDomainEventColumn.Mismatch
  }
]
