import { IHighOrderColumn } from '@/types/interfaces/table'

const COLUMNS_NUMBER = {
  GENERAL: 4,
  // There can be additional Site column for enterprise portal
  // or Gate column for site portal when site has more than 1 gate
  GENERAL_WITH_ADDITIONAL_COLUMN: 5,
  POWER_UNIT: 11,
  DRIVER_DETAILS: 3,
  CARGO_ASSET: 12
}

const gateTransactionsHighOrderColumns = (
  hasAdditionalGeneralColumn?: boolean
): IHighOrderColumn[] => [
  {
    id: 'general',
    title: 'General',
    colSpan: hasAdditionalGeneralColumn
      ? COLUMNS_NUMBER.GENERAL_WITH_ADDITIONAL_COLUMN
      : COLUMNS_NUMBER.GENERAL
  },
  {
    id: 'power-unit',
    title: 'Power Unit',
    colSpan: COLUMNS_NUMBER.POWER_UNIT
  },
  {
    id: 'driver',
    title: 'Driver Details',
    colSpan: COLUMNS_NUMBER.DRIVER_DETAILS
  },
  {
    id: 'cargo-asset',
    title: 'Cargo Asset',
    colSpan: COLUMNS_NUMBER.CARGO_ASSET
  }
]

export default gateTransactionsHighOrderColumns
