import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import { Row } from '@/components/atoms'
import { IRowColProps } from '@/types/interfaces/ui'

import styles from './Col.module.scss'

type IColProps = IRowColProps

const Col: FC<PropsWithChildren<IColProps>> = ({
  className,
  children,
  reverse,
  ...rest
}: IColProps) => (
  <Row
    className={clsx(styles.column, reverse && styles.reverse, className)}
    items="stretch"
    {...rest}
  >
    {children}
  </Row>
)

export default Col
