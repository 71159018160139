import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'

import { withParams } from '@/api/helpers'
import ROUTES from '@/router/routes'
import { URL_PART_SEPARATOR } from './AppRedirector'

class Navigator {
  navigate: NavigateFunction

  readonly siteId: string
  readonly gateId: string

  location = useLocation()

  constructor(navigate: NavigateFunction, siteId = '', gateId = '') {
    this.navigate = navigate

    this.siteId = siteId
    this.gateId = gateId
  }

  public to = (path: string) => {
    this.navigate(path)
  }

  public toSite = (siteId: string) => {
    this.navigate(withParams(ROUTES.GATE, { site_id: siteId }))
  }

  public toHome = () => {
    this.navigate(ROUTES.HOME)
  }

  public toGateQueue = () => {
    this.navigateWithParams(ROUTES.QUEUES)
  }

  public toCheckIn = (eventAndLaneId?: string) => {
    if (eventAndLaneId) {
      this.navigate(
        withParams(ROUTES.CHECK_IN, {
          site_id: this.siteId,
          gate_id: this.gateId,
          event_and_lane_id: eventAndLaneId
        })
      )
    } else {
      this.navigateWithParams(ROUTES.MANUAL_CHECK_IN)
    }
  }

  public toCheckOut = (eventAndLaneId?: string) => {
    if (eventAndLaneId) {
      this.navigate(
        withParams(ROUTES.CHECK_OUT, {
          site_id: this.siteId,
          gate_id: this.gateId,
          event_and_lane_id: eventAndLaneId
        })
      )
    } else {
      this.navigateWithParams(ROUTES.MANUAL_CHECK_OUT)
    }
  }

  public toGateDrivers = () => {
    this.navigateWithParams(ROUTES.DRIVERS)
  }

  public toGateTransactions = () => {
    this.navigateWithParams(ROUTES.TRANSACTIONS)
  }

  public toCargoAssets = () => {
    this.navigateWithParams(ROUTES.CARGO_ASSETS)
  }

  public toISR = () => {
    this.navigateWithParams(ROUTES.ISR)
  }

  public toEnterpriseHome = () => {
    this.navigate(ROUTES.ENTERPRISE)
  }

  public toEnterpriseUsers = () => {
    this.navigateWithParams(ROUTES.ENTERPRISE_USERS)
  }

  public toEnterpriseInvites = () => {
    this.navigateWithParams(ROUTES.ENTERPRISE_INVITES)
  }

  public toProfile = () => {
    this.navigate(ROUTES.PROFILE)
  }

  public goBackOneLevel = () => {
    const parts = location.pathname.split(URL_PART_SEPARATOR).filter(Boolean)
    if (parts.length < 2) return
    parts.pop()
    this.navigate(URL_PART_SEPARATOR + parts.join(URL_PART_SEPARATOR))
  }

  public toEntity = (entityId: string) => {
    this.navigateWithParams(`${this.location.pathname}/${entityId}`)
  }

  private navigateWithParams(url: string) {
    this.navigate(
      withParams(url, { site_id: this.siteId, gate_id: this.gateId })
    )
  }
}

export function useNavigator(siteId = '', gateId = '') {
  const navigate = useNavigate()

  return new Navigator(navigate, siteId, gateId)
}
