import SearchIcon from '@mui/icons-material/Search'
import { Collapse } from '@mui/material'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react'

import { Button, Col, Input, Row, Text } from '@/components/atoms'
import { useTableContext } from '@/components/contexts'
import {
  FilterDropdown,
  GroupByDropdown
} from '@/components/organisms/Table/components'

import { Modal } from '@/components/organisms'
import { DEBOUNCE_TIME } from '@/constants'
import { TableColumnType } from '@/types/enums/table'
import { IHighOrderColumn, ITableColumn } from '@/types/interfaces/table'
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined'
import { ColumnState, GridApi } from 'ag-grid-community'
import React from 'react'
import { useDebounceValue } from 'usehooks-ts'
import toggleColumnVisibilities from '../../AgGrid/helpers/toggleColumnVisibilities'
import ManageColumnCheckbox from './ManageColumnCheckbox'
import { ManageColumnState } from './ManagedColumnState'
import styles from './TableFilters.module.scss'
import { FontWeight, TextTypes } from '@/types/enums/ui'

interface IProps {
  savedSearchValue?: string | undefined
  showSearch?: boolean
  columns: ITableColumn[]
  columnState: ColumnState[]
  setColumnState: Dispatch<SetStateAction<ColumnState[]>>
  gridApi: GridApi
  resetColumnState: () => void
  higherOrderColumns?: IHighOrderColumn[]
}

const TableFilters: FC<IProps> = (props) => {
  const {
    savedSearchValue,
    showSearch = false,
    columns,
    columnState,
    setColumnState,
    gridApi,
    resetColumnState,
    higherOrderColumns
  } = props

  const {
    filters,
    filterValues,
    searchPlaceholder,
    groupByOptions,
    onSearchChange
  } = useTableContext()

  const COLLAPSE_TIMEOUT = 400
  const SEARCH_ICON_SIZE = 16

  const getManageColumnsCheckboxState = () => {
    return columns.map((column) => ({
      colId: column.id,
      hidden:
        columnState.find((state) => state.colId === column.id)?.hide ??
        !columns.find((col) => col.id === column.id)?.defaultVisible
    }))
  }

  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(
    undefined
  )
  const [manageColumnsModalOpen, setManageColumnsModalOpen] =
    useState<boolean>(false)
  // There is no need to debounce the search value for the columns
  const [searchColumnsValue, setSearchColumnsValue] = useState<string>('')
  const [manageColumnsCheckboxState, setManageColumnsCheckboxState] = useState<
    ManageColumnState[]
  >(getManageColumnsCheckboxState())

  const [debouncedSearchValue] = useDebounceValue(
    searchInputValue,
    DEBOUNCE_TIME
  )

  const showFilters = Array.isArray(filters)
  const showGroupBy = Array.isArray(groupByOptions)

  const isFilterApplied = useMemo(
    () =>
      Object.values(filterValues || {}).some((filterValue) => !!filterValue),
    [filterValues]
  )

  useEffect(() => {
    onSearchChange(debouncedSearchValue || '')
  }, [debouncedSearchValue])

  useEffect(() => {
    if (searchInputValue !== savedSearchValue) {
      setSearchInputValue(savedSearchValue)
    }
  }, [savedSearchValue])

  const saveColumnState = () => {
    setManageColumnsModalOpen(false)

    setColumnState(
      manageColumnsCheckboxState.map((state) => {
        return {
          ...state,
          hide: state.hidden
        }
      })
    )

    toggleColumnVisibilities(gridApi, manageColumnsCheckboxState, columnState)
  }

  const onResetClicked = () => {
    resetColumnState()
    setManageColumnsCheckboxState(
      columns.map((column) => ({
        colId: column.id,
        hidden: !column.defaultVisible
      }))
    )
    setManageColumnsModalOpen(false)
  }

  const getColumnGroup = (columnIndex: number) => {
    if (!higherOrderColumns) return null
    let currentPosition = 0
    for (const highOrderCol of higherOrderColumns) {
      if (columnIndex === currentPosition) return highOrderCol
      currentPosition += highOrderCol.colSpan
    }
    return null
  }

  const filteredColumns = useMemo(
    () =>
      columns
        .filter((column) => column.id !== TableColumnType.Actions)
        .filter((column) =>
          column.title.toLowerCase().includes(searchColumnsValue.toLowerCase())
        ),
    [columns, searchColumnsValue]
  )

  const ManageColumnsModal = (
    <Modal
      title="Manage Columns"
      placement="right"
      disableCloseOnBackdropClick
      closeModal={() => {
        setManageColumnsModalOpen(false)
        setSearchColumnsValue('')
        setManageColumnsCheckboxState(getManageColumnsCheckboxState())
      }}
      cancelButtonText="Cancel"
      footerGap={64}
      footer={
        <Button type="primary" onClick={saveColumnState}>
          Save
        </Button>
      }
      footerLeft={
        <Button small onClick={onResetClicked} type="text">
          Reset to Default
        </Button>
      }
    >
      <Col>
        <Input
          size="medium"
          type="text"
          variant="outlined"
          value={searchColumnsValue}
          onChange={(e) => setSearchColumnsValue(e.target.value)}
          placeholder={'Search Columns'}
          className="background-color-gray0"
          icon={
            <SearchIcon width={SEARCH_ICON_SIZE} height={SEARCH_ICON_SIZE} />
          }
        />
        <div className={styles.selectColumnsContainer}>
          {filteredColumns.map((column, index) => {
            const group = getColumnGroup(index)

            return (
              <React.Fragment key={column.id}>
                {group && (
                  <Text
                    weight={FontWeight.BOLD}
                    type={TextTypes.TEXT_XS}
                    className={styles.tableFilterGroupHeader}
                  >
                    {group.title}
                  </Text>
                )}
                <ManageColumnCheckbox
                  column={column}
                  managedColumnsIntermediaryState={manageColumnsCheckboxState}
                  setManageColumnsIntermediaryState={
                    setManageColumnsCheckboxState
                  }
                />
              </React.Fragment>
            )
          })}
        </div>
      </Col>
    </Modal>
  )

  return (
    <Col>
      {manageColumnsModalOpen && ManageColumnsModal}
      <Row
        justify="between"
        gap={20}
        items="center"
        className={styles.tableFilters}
      >
        {(showFilters || showSearch) && (
          <Row gap={8}>
            {showSearch && (
              <Input
                size="small"
                type="text"
                variant="outlined"
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                placeholder={searchPlaceholder}
                className="background-color-gray0"
                icon={
                  <SearchIcon
                    width={SEARCH_ICON_SIZE}
                    height={SEARCH_ICON_SIZE}
                  />
                }
              />
            )}

            {showFilters && (
              <FilterDropdown isFilterApplied={isFilterApplied} />
            )}
          </Row>
        )}

        {showGroupBy && <GroupByDropdown />}
        <Button
          ariaLabel="Manage Columns"
          small
          onClick={() => setManageColumnsModalOpen(true)}
          type="outlined"
          startIcon={<ViewColumnOutlinedIcon />}
        />
      </Row>

      <Collapse
        unmountOnExit={false}
        orientation="vertical"
        timeout={COLLAPSE_TIMEOUT}
        in={isFilterApplied}
      >
        <div id="filters-portal" className="tw-overflow-y-auto" />
      </Collapse>
    </Col>
  )
}

export default TableFilters
