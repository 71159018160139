import milliSecondsToDuration from '@/utils/data/milliSecondToDuration'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { get } from 'lodash'

dayjs.extend(duration)

const milliSecondsToDurationTableConverter = (row: any, id: string) => {
  return milliSecondsToDuration(get(row, id))
}

export default milliSecondsToDurationTableConverter
