import { sanityClient } from '@/services/sanity/client'
import getEnvironment from '@/utils/helpers/getNodeEnvironment'
import { useEffect, useState } from 'react'

interface AppWideBanner {
  header: string
  description: string
  enabled: boolean
  environment: string
}

const useAppWideBanners = () => {
  const SCHEMA_ID = 'appwide_banner'

  const [banners, setBanners] = useState<AppWideBanner[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const environment = getEnvironment()

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const result = await sanityClient.fetch(
          `*[_type == "${SCHEMA_ID}" && environment == $env]`,
          { env: environment }
        )

        setBanners(result)
      } catch (err: any) {
        setError(err.message || 'An error occurred')
      } finally {
        setLoading(false)
      }
    }

    fetchBanners()
  }, [environment])

  return { banners, loading, error }
}

export default useAppWideBanners
