import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Autocomplete, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ISelectOption, ReadOnlyProps } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import { isNotVisitor } from '@/features/gate/utils'
import { withPrefix } from '@/utils/helpers'

interface Props extends ReadOnlyProps {
  idPrefix?: string
}

const LicenseState: FC<Props> = (props) => {
  const { readOnly, label = 'Driver ID State/Region', idPrefix } = props
  const { control, watch } = useFormContext()

  const fieldId = withPrefix(FORM_IDS.DRIVER_DETAILS.LICENSE_STATE, idPrefix)
  const driverLicenseState = watch(fieldId)
  const appointmentType = watch(FORM_IDS.GENERAL.APPOINTMENT_TYPE)

  const required = isNotVisitor(appointmentType)

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={driverLicenseState} />
  }

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          required={required}
          freeSolo={false}
          label={label}
          options={statesOptions}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onChange={(value: ISelectOption) => field.onChange(value?.id || '')}
          isOptionEqualToValue={(option: ISelectOption) =>
            option.id === field.value
          }
        />
      )}
    />
  )
}

export default LicenseState
