import { curveLinear } from '@visx/curve'
import { LinearGradient } from '@visx/gradient'
import {
  AreaSeries,
  LineSeries,
  Tooltip,
  TooltipProvider,
  XYChart
} from '@visx/xychart'
import HeaderContainer from './Header/HeaderContainer'
import TimeseriesLegend from './Legend/TimeseriesLegend'
import HorizontalLines from './XAxis/HorizontalLines'
import XAxisLabels from './XAxis/XAxisLabels'
import YAxisLabels from './YAxis/YAxisLabels'

import {
  noDataTimeseriesScalarConfig,
  timeseriesScalarAccessors,
  timeseriesScalarConfig
} from './helpers/config'
import { generateTimeseriesChartTheme } from './helpers/theme'
import { TimeseriesConfig } from './helpers/TimeseriesConfig'

import { Row } from '@/components/atoms'
import { ROLLUP_WIDGET_COLORS } from '../Shared/Colors/RollupColors'
import StatisticHeader from '../Shared/Headers/StatisticHeader'
import NoDataAvailable from '../Shared/Unavailable/NoDataAvailable'
import TimeseriesIntersectionGlyph from './Glyph/TimeseriesIntersectionGlyph'
import { VERTICAL_CROSSHAIR_STYLE } from './helpers/verticalCrosshairStyle'
import styles from './TimeseriesChart.module.scss'
import TimeseriesTooltip from './Tooltip/TimeseriesTooltip'
import { TooltipDatum } from './Tooltip/TooltipDatum'
import tickFormatter, {
  DEFAULT_LOCALS,
  FULL_MONTH_DATE_YEAR_OPTIONS
} from './XAxis/tickFormatter'

export interface TimeseriesHeaderRightLabel {
  label: string
  color: string
}

interface TimeseriesChartProps {
  chartTitle?: string
  subHeader?: string
  series?: TimeseriesConfig[]
  width?: number
  height?: number
  numHorizontalLines?: number
  chartXPadding?: number
  chartYPadding?: number
  scalarConfig?: any
  noDataScalarConfig?: any
  colors?: string[]
  accessors?: any
  dataPointNotAvailableLabelText?: string
  timeseriesHeaderRightLabel?: TimeseriesHeaderRightLabel
}

const NOT_AVAILABLE = 'N/A'

const DEFAULT_CHART_HEIGHT = 336
const DEFAULT_CHART_X_PADDING = 20
const DEFAULT_CHART_Y_PADDING = 25
const DEFAULT_NUM_HORIZONTAL_LINES = 5

const TimeseriesChart = ({
  chartTitle,
  subHeader,
  series = [],
  height = DEFAULT_CHART_HEIGHT,
  numHorizontalLines = DEFAULT_NUM_HORIZONTAL_LINES,
  chartXPadding = DEFAULT_CHART_X_PADDING,
  chartYPadding = DEFAULT_CHART_Y_PADDING,
  scalarConfig = timeseriesScalarConfig,
  noDataScalarConfig = noDataTimeseriesScalarConfig,
  colors = ROLLUP_WIDGET_COLORS,
  accessors = timeseriesScalarAccessors,
  dataPointNotAvailableLabelText = NOT_AVAILABLE,
  timeseriesHeaderRightLabel
}: TimeseriesChartProps) => {
  const GRADIENT_ID = 'area-gradient'

  const isAreaGraph = series.length === 1
  const noTimeseriesPoints = series.every((line) => line.data.length === 0)
  const noData = series.length === 0 || noTimeseriesPoints

  const margins = {
    top: chartYPadding,
    right: chartXPadding,
    bottom: chartYPadding,
    left: chartXPadding
  }

  const HeaderComponent = (
    <HeaderContainer chartXPadding={chartXPadding}>
      <StatisticHeader
        headerText={chartTitle}
        subHeaderText={subHeader}
        rightLabelText={timeseriesHeaderRightLabel?.label}
        rightLabelLineColor={timeseriesHeaderRightLabel?.color}
      />
      <TimeseriesLegend colors={colors} series={series} />
    </HeaderContainer>
  )

  const NoDataTimeSeries = (
    <LineSeries
      key="no-data-key"
      dataKey="no-data-key"
      data={[
        {
          x: '1/1/2025',
          y: 0
        }
      ]}
      xAccessor={accessors.xAccessor}
      yAccessor={accessors.yAccessor}
      curve={curveLinear}
    />
  )

  const getColorForSeries = (seriesIndex: number, fallback = colors[0]) => {
    return colors[seriesIndex] || fallback
  }

  const ChartComponent = (
    <TooltipProvider>
      <XYChart
        height={height}
        xScale={noData ? noDataScalarConfig.x : scalarConfig.x}
        yScale={noData ? noDataScalarConfig.y : scalarConfig.y}
        theme={generateTimeseriesChartTheme(colors)}
        margin={margins}
      >
        <defs>
          <LinearGradient
            id={GRADIENT_ID}
            from={colors[0]}
            to={colors[0]}
            toOpacity={0}
            fromOpacity={0.65}
          />
        </defs>

        <HorizontalLines numTicks={numHorizontalLines} />

        {noData && NoDataTimeSeries}

        {series.map((line, index) =>
          isAreaGraph ? (
            <g clipPath={`url(#clip-path)`} key={line.key}>
              <AreaSeries
                dataKey={line.key}
                data={line.data}
                xAccessor={accessors.xAccessor}
                yAccessor={accessors.yAccessor}
                curve={curveLinear}
                fill={`url(#${GRADIENT_ID})`}
                stroke={colors[index]}
                strokeWidth={0}
              />
            </g>
          ) : (
            <LineSeries
              key={line.key}
              dataKey={line.key}
              data={line.data}
              xAccessor={accessors.xAccessor}
              yAccessor={accessors.yAccessor}
              curve={curveLinear}
            />
          )
        )}

        <XAxisLabels />
        <YAxisLabels numTicks={numHorizontalLines} />

        {!noData && (
          <Tooltip
            className={styles.tooltip}
            showVerticalCrosshair
            snapTooltipToDatumX
            showSeriesGlyphs
            renderGlyph={({ color }) => (
              <TimeseriesIntersectionGlyph color={color} />
            )}
            verticalCrosshairStyle={VERTICAL_CROSSHAIR_STYLE}
            renderTooltip={({ tooltipData }) => {
              if (!tooltipData || !tooltipData.nearestDatum) return null

              const { datumByKey } = tooltipData
              const keys = Object.keys(datumByKey)

              const labels = keys.map((key) => {
                const datum = datumByKey[key].datum as TooltipDatum
                const foundIndex = series.findIndex(
                  (point) => point.key === key
                )

                return {
                  title:
                    series[foundIndex]?.label ?? dataPointNotAvailableLabelText,
                  value: datum.y.toString() || dataPointNotAvailableLabelText,
                  color: getColorForSeries(foundIndex)
                }
              })

              const firstDatum = datumByKey[keys[0]].datum as TooltipDatum
              const formattedDate = tickFormatter(
                firstDatum?.x,
                DEFAULT_LOCALS,
                FULL_MONTH_DATE_YEAR_OPTIONS
              )

              return (
                <TimeseriesTooltip
                  title={formattedDate || dataPointNotAvailableLabelText}
                  labels={labels}
                />
              )
            }}
          />
        )}
      </XYChart>
    </TooltipProvider>
  )

  return (
    <Row className={styles.timeseriesChart}>
      {HeaderComponent}
      {ChartComponent}
      {noData && (
        <Row className={styles.overlay}>
          <NoDataAvailable />
        </Row>
      )}
    </Row>
  )
}

export default TimeseriesChart
