import useAppWideBanners from '@/hooks/useAppWideBanners'
import { WarningAmber } from '@mui/icons-material'
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import AppBanner from './AppBanner'

interface AppBannerState {
  header: string | undefined
  headerIcon?: ReactNode | undefined
  body: string | undefined
}

interface AppBannerContextProps {
  state: AppBannerState
  setAppBanner: (header: string, headerIcon: ReactNode, body: string) => void
  clearAppBanner: () => void
}

const AppBannerContext = createContext<AppBannerContextProps | undefined>(
  undefined
)

export const AppBannerProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<AppBannerState>({
    header: undefined,
    headerIcon: undefined,
    body: undefined
  })
  const [environmentBannerEnabled, setEnvironmentBannerEnabled] =
    useState<boolean>(false)

  const { banners, loading } = useAppWideBanners()

  useEffect(() => {
    if (loading || !banners || banners.length < 1) return
    const enabledBanners = banners.filter((banner) => banner.enabled)
    if (enabledBanners.length === 0) return
    if (enabledBanners.length > 1) {
      console.warn(
        'More than one enabled banner found. Only one banner can be shown at a time.'
      )
    }

    const firstEnabledBanner = enabledBanners[0]
    setEnvironmentBannerEnabled(true)
    setState({
      header: firstEnabledBanner.header,
      headerIcon: <WarningAmber />,
      body: firstEnabledBanner.description
    })
  }, [banners, loading])

  const setAppBanner = (
    newHeader: string,
    newHeaderIcon: ReactNode,
    newBody: string
  ) => {
    setState({
      header: newHeader,
      headerIcon: newHeaderIcon,
      body: newBody
    })
  }

  const clearAppBanner = () => {
    setState({
      header: undefined,
      headerIcon: undefined,
      body: undefined
    })
  }

  return (
    <AppBannerContext.Provider
      value={{
        state,
        setAppBanner,
        clearAppBanner
      }}
    >
      {state.header && environmentBannerEnabled && (
        <AppBanner
          header={state.header}
          headerIcon={state.headerIcon}
          body={state.body}
        />
      )}
      {children}
    </AppBannerContext.Provider>
  )
}

export const useAppBanner = () => {
  const context = useContext(AppBannerContext)
  if (!context) {
    throw new Error('useAppBanner must be used within an AppBannerContext')
  }
  return context
}
