import { ErrorTemplate } from '@/components/templates'
import { ErrorType } from '@/types/enums/ui'
import { useEffect } from 'react'
import { generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom'
import ROUTES from './routes'

export const URL_PART_SEPARATOR = '/'

const LEGACY_PATH_REGEX = /^\/([0-9a-fA-F-]{36})\/([0-9a-fA-F-]{36})(.*)$/

const LEGACY_PATH_MAPPINGS = {
  '/yard/gate-drivers': '/drivers',
  '/yard/cargo-asset': '/cargo-assets',
  '/gate/queue': '/queues',
  '/gate/transactions': '/transactions'
}

/**
 * The app redirector component is responsible for the following in order of priority:
 *  - redirecting legacy URLs to the new URLs
 *  - redirecting invalid routes to a valid route if one can be found by walking up the URL path
 *  - showing a 404 error page if no valid route can be found
 */
const AppRedirector = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const attemptLegacyPathMatchAndRedirect = () => {
    const match = location.pathname.match(LEGACY_PATH_REGEX)
    if (!match) return false

    const [_, site_id, __, rest] = match

    let newRest = rest
    if (rest in LEGACY_PATH_MAPPINGS) {
      newRest = LEGACY_PATH_MAPPINGS[rest as keyof typeof LEGACY_PATH_MAPPINGS]
    }

    const newPath = generatePath(ROUTES.GATE, { site_id }) + newRest;
    navigate(newPath, { replace: true })
    return true
  }

  const isValidRoute = (path: string): boolean => {
    const routePatterns = Object.values(ROUTES)
    return routePatterns.some((pattern) => matchPath(pattern, path))
  }

  const attemptToRedirectToValidRoute = () => {
    if (location.pathname === ROUTES.HOME || isValidRoute(location.pathname))
      return

    const segments = location.pathname.split(URL_PART_SEPARATOR).filter(Boolean)

    // Attempt to find the longest valid route that can be navigated to based on the current path
    for (let i = segments.length - 1; i >= 0; i--) {
      const testPath = URL_PART_SEPARATOR + segments.slice(0, i + 1).join(URL_PART_SEPARATOR)

      if (isValidRoute(testPath)) {
        navigate(testPath, { replace: true })
        return
      }
    }

    navigate(ROUTES.HOME, { replace: true })
  }

  useEffect(() => {
    if (attemptLegacyPathMatchAndRedirect()) return
    attemptToRedirectToValidRoute()
  }, [location.pathname, navigate])

  return <ErrorTemplate type={ErrorType.NotFound} />
}

export default AppRedirector
