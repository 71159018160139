import { ROUTES, useNavigator } from '@/router'
import { useStore } from '@/store'
import { PortalTypes } from '@/types/enums/global'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Redirects the root path to the currently selected portal or the first portal if none is selected.
 */
const usePortalRedirector = () => {
  const navigate = useNavigator()
  const location = useLocation()

  const { selectedPortal, portals } = useStore((store) => store.user)

  useEffect(() => {
    if (location.pathname !== ROUTES.HOME || !portals.length) return
    const redirectToPortalId = selectedPortal?.id || portals[0].id
    if (redirectToPortalId === PortalTypes.Enterprise) {
      navigate.toEnterpriseHome()
    } else {
      navigate.toSite(redirectToPortalId)
    }
  }, [portals, selectedPortal, location.pathname])
}

export default usePortalRedirector
