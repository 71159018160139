import { Row, Text } from '@/components/atoms'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import LegendLine from '../../Timeseries/Legend/LegendLine'
import styles from './StatisticHeader.module.scss'

interface StatisticHeaderProps {
  headerText?: string
  subHeaderText?: string
  rightLabelText?: string
  rightLabelLineColor?: string
}

const StatisticHeader = ({
  headerText,
  subHeaderText,
  rightLabelText,
  rightLabelLineColor
}: StatisticHeaderProps) => {
  return (
    <Row className={styles.headerRow}>
      <div className={styles.headerLabelsContainer}>
        <Text type={TextTypes.TEXT_LG} weight={FontWeight.SEMIBOLD}>
          {headerText}
        </Text>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.REGULAR}
          className={styles.subHeader}
        >
          {subHeaderText}
        </Text>
      </div>
      {rightLabelText && rightLabelLineColor && (
        <div className={styles.rightLabelContainer}>
          <LegendLine lineColor={rightLabelLineColor} />
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.REGULAR}
            className={styles.subHeader}
          >
            {rightLabelText}
          </Text>
        </div>
      )}
    </Row>
  )
}

export default StatisticHeader
