import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import config from '@/config'

const sentryDsn = import.meta.env.VITE_SENTRY_DSN

const initSentry = () => {
  if (!config.isDev) {
    Sentry.init({
      dsn: sentryDsn,
      integrations: (defaultIntegrations) => [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        ...(defaultIntegrations || []).filter(
          (integration) => integration.name !== 'ReactProfiling'
        )
      ],

      debug: false,
      attachStacktrace: true,
      tracesSampleRate: 0.2,
      ignoreErrors: [
        'Network Error',
        'Failed to load resource',
        'Warning: Using UNSAFE_componentWillMount',
        'Unchecked runtime.lastError: The message port closed before a response was received.'
      ],

      tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/]
    })
  }
}

export default initSentry
