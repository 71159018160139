import { IHighOrderColumn } from '@/types/interfaces/table'

const isrHighOrderColumns = (
  groupBy: string | undefined,
  enterpriseMode: boolean
): IHighOrderColumn[] => [
  ...(groupBy
    ? [
        {
          id: 'title',
          title: '',
          colSpan: 1
        }
      ]
    : []),
  {
    id: 'transaction',
    title: 'Record',
    // [Check-in Time | Check-out Time | Site (only in enterprise mode)]
    colSpan: enterpriseMode ? 3 : 2
  },
  {
    id: 'power-unit',
    title: 'Power Unit',
    colSpan: 9
  },
  {
    id: 'driver',
    title: 'Driver Details',
    colSpan: 2
  },
  {
    id: 'check-in-asset',
    title: 'Check-In Cargo Asset',
    colSpan: 6
  },
  {
    id: 'check-out-asset',
    title: 'Check-Out Cargo Asset',
    colSpan: 8
  }
]

export default isrHighOrderColumns
