import * as Sentry from '@sentry/react'
import { Route, Routes } from 'react-router-dom'

import ProtectedRoute from '@/components/organisms/Navigation/components/ProtectedRoute/ProtectedRoute'
import { MainTemplate } from '@/components/templates'
import config from '@/config'
import { SitePortalPermissionKeys } from '@/features/auth/types'
import {
  GateDriversTemplate,
  GateQueueTemplate,
  GateTemplate,
  GateTransactionTemplate
} from '@/features/gate'
import { HomeTemplate } from '@/features/home'
import { ISRTemplate } from '@/features/isr'
import { ProfileTemplate } from '@/features/profile'
import { UserManagementTemplate } from '@/features/user-management'
import { CargoAssetTemplate } from '@/features/yard'
import ROUTES from '@/router/routes'
import AppRedirector from './AppRedirector'

const SentryRoutes = !config.isDev
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes

const RootRouter = () => {
  return (
    <SentryRoutes>
      <Route element={<MainTemplate />}>
        {/* Site portal routes */}
        <Route
          path={ROUTES.GATE}
          element={
            <ProtectedRoute
              requiredPermission={SitePortalPermissionKeys.GATE_QUEUE}
            >
              <GateTemplate />
            </ProtectedRoute>
          }
        >
          <Route
            path={ROUTES.QUEUES}
            element={
              <ProtectedRoute
                requiredPermission={SitePortalPermissionKeys.GATE_QUEUE}
              >
                <GateQueueTemplate />
              </ProtectedRoute>
            }
          >
            <Route path={ROUTES.MANUAL_CHECK_IN}>
              <Route path={ROUTES.CHECK_IN} />
            </Route>

            <Route path={ROUTES.MANUAL_CHECK_OUT}>
              <Route path={ROUTES.CHECK_OUT} />
            </Route>
          </Route>

          <Route
            path={ROUTES.TRANSACTIONS}
            element={
              <ProtectedRoute
                requiredPermission={SitePortalPermissionKeys.GATE_TRANSACTION}
              >
                <GateTransactionTemplate />
              </ProtectedRoute>
            }
          >
            <Route path={ROUTES.TRANSACTION} />
          </Route>
        </Route>

        <Route
          path={ROUTES.DRIVERS}
          element={
            <ProtectedRoute
              requiredPermission={SitePortalPermissionKeys.ONSITE_DRIVER}
            >
              <GateDriversTemplate />
            </ProtectedRoute>
          }
        >
          <Route path={ROUTES.DRIVER} />
        </Route>

        <Route
          path={ROUTES.ISR}
          element={
            <ProtectedRoute requiredPermission={SitePortalPermissionKeys.ISR}>
              <ISRTemplate />
            </ProtectedRoute>
          }
        >
          <Route path={ROUTES.ISR_TRIP} />
        </Route>

        <Route
          path={ROUTES.CARGO_ASSETS}
          element={
            <ProtectedRoute
              requiredPermission={SitePortalPermissionKeys.ONSITE_CARGO_ASSET}
            >
              <CargoAssetTemplate />
            </ProtectedRoute>
          }
        >
          <Route path={ROUTES.CARGO_ASSET} />
        </Route>

        {/* Enterprise routes */}
        <Route path={ROUTES.ENTERPRISE} element={<HomeTemplate />} />
        <Route
          path={ROUTES.ENTERPRISE_USERS}
          element={<UserManagementTemplate />}
        />
        <Route
          path={ROUTES.ENTERPRISE_TRANSACTIONS}
          element={<GateTransactionTemplate enterpriseMode />}
        >
          <Route path={ROUTES.ENTERPRISE_TRANSACTION} />
        </Route>
        <Route
          path={ROUTES.ENTERPRISE_DRIVERS}
          element={<GateDriversTemplate enterpriseMode />}
        >
          <Route path={ROUTES.ENTERPRISE_DRIVER} />
        </Route>
        <Route
          path={ROUTES.ENTERPRISE_CARGO_ASSETS}
          element={<CargoAssetTemplate enterpriseMode />}
        >
          <Route path={ROUTES.ENTERPRISE_CARGO_ASSET} />
        </Route>
        <Route
          path={ROUTES.ENTERPRISE_ISR}
          element={<ISRTemplate enterpriseMode />}
        >
          <Route path={ROUTES.ENTERPRISE_ISR_TRIP} />
        </Route>
        <Route
          path={ROUTES.ENTERPRISE_INVITES}
          element={<UserManagementTemplate />}
        />

        {/* Common routes */}
        <Route path={ROUTES.HOME} element={<HomeTemplate />} />
        <Route path={ROUTES.PROFILE} element={<ProfileTemplate />} />

        <Route path="*" element={<AppRedirector />} />
      </Route>
    </SentryRoutes>
  )
}

export default RootRouter
