import { Row } from '@/components/atoms'
import TerminalDocumentTitle from '@/components/contexts/Title/TerminalDocumentTitle'
import { BaseEmptyState } from '@/components/organisms'
import { Queues } from '@/features/gate'
import { useStore } from '@/store'

const GateQueueTemplate = () => {
  const { selectedGate } = useStore((store) => store.user || {})

  const lanes = selectedGate?.lanes || []

  const lanesOrGates = !lanes.length ? 'lanes' : 'gates'

  return (
    <Row items="stretch" className="tw-flex-1">
      <TerminalDocumentTitle title="Queue" />
      {lanes.length ? (
        <Queues />
      ) : (
        <Row
          gap={8}
          items="center"
          justify="center"
          className="tw-w-full tw-h-full"
        >
          <BaseEmptyState
            primaryText={`There are no ${lanesOrGates}`}
            descriptionText={`The selected site does not contain any ${lanesOrGates}.`}
          />
        </Row>
      )}
    </Row>
  )
}

export default GateQueueTemplate
