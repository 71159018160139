import { InputAdornment } from '@mui/material'
import { DesktopTimePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { forwardRef, ForwardRefRenderFunction, useState } from 'react'

import { TimeInputProps } from '@/types/interfaces/ui'

import { TimeUnit } from '@/types/enums/ui'
import Button from '../../Button/Button'
import Row from '../../Row/Row'
import classes from '../classes'

import styles from './TimeInput.module.scss'

const TimeInput: ForwardRefRenderFunction<HTMLInputElement, TimeInputProps> = (
  props,
  ref
) => {
  const {
    icon,
    endIcon,
    fullWidth,
    required,
    placeholder,
    helperText,
    hideDayTime,
    error = false,
    minutesStep = 5,
    ...restProps
  } = props

  const {
    inputClasses,
    inputLabelClasses,
    helperTextClasses,
    iconClasses,
    endIconClasses,
    rootClasses
  } = classes

  const [open, setOpen] = useState<boolean>(false)

  const additionalProps = hideDayTime
    ? {
        ampm: false,
        minTime: dayjs().set(TimeUnit.Hour, 1).set(TimeUnit.Minute, 0)
      }
    : {}

  return (
    <DesktopTimePicker
      {...restProps}
      {...additionalProps}
      ref={ref}
      open={open}
      timeSteps={{ minutes: minutesStep }}
      onClose={() => setOpen(false)}
      slots={{
        actionBar: (props) => {
          return (
            <Row items="end" justify="end" className={styles.actionBar}>
              <Button type="outlined" onClick={props.onCancel} small>
                Cancel
              </Button>
              <Button type="primary" onClick={props.onAccept} small>
                Save
              </Button>
            </Row>
          )
        }
      }}
      slotProps={{
        layout: {
          sx: {
            display: 'flex',
            flexDirection: 'column'
          }
        },
        textField: {
          error,
          required,
          placeholder,
          helperText,
          variant: 'standard',
          onClick: (e) => {
            e.stopPropagation()
            setOpen(() => !open)
          },
          classes: {
            root: clsx(rootClasses.root, fullWidth ? 'tw-w-full' : 'tw-w-115')
          },
          InputProps: {
            required,
            fullWidth,
            startAdornment: icon && (
              <InputAdornment classes={iconClasses} position="start">
                {icon}
              </InputAdornment>
            ),
            endAdornment: endIcon && (
              <InputAdornment classes={endIconClasses} position="end">
                {endIcon}
              </InputAdornment>
            ),
            classes: inputClasses
          },
          InputLabelProps: {
            required,
            classes: inputLabelClasses
          },
          FormHelperTextProps: {
            classes: helperTextClasses
          }
        }
      }}
    />
  )
}

export default forwardRef(TimeInput)
