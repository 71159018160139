import { useNavigator } from '@/router'
import { PortalTypes } from '@/types/enums/global'

const useNavigateHome = () => {
  const navigator = useNavigator()

  return (portalId?: string) => {
    if (portalId === PortalTypes.Enterprise) {
      navigator.toEnterpriseHome()
    } else if (portalId) {
      navigator.toSite(portalId)
    } else {
      navigator.toHome()
    }
  }
}

export default useNavigateHome
