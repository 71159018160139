import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounceValue } from 'usehooks-ts'

import { Autocomplete, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { NameType } from '@/features/forms/enums'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { useQuery } from '@apollo/client'
import { GetGateTransactionsQuery } from '@/features/gate/api/queries'
import { DEBOUNCE_TIME, PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import {
  DistinctFieldNames,
  GateTransaction,
  OrderDirection,
  StringOperator
} from '@/__generated__/graphql'
import { useStore } from '@/store'
import { DriverNameAutocompleteOption } from '@/features/forms/components'
import { DomainEventTypes } from '@/features/gate/enums'
import { withPrefix } from '@/utils/helpers'

interface IProps {
  type: NameType
  idPrefix?: string
  selectedDriverId: string | undefined
  setSelectedDriverId: Dispatch<SetStateAction<string | undefined>>
}

const namesMapper = {
  [NameType.First]: 'First Name',
  [NameType.Last]: 'Last Name',
  [NameType.Full]: 'Driver Name'
}

const idsMapper = {
  [NameType.First]: FORM_IDS.DRIVER_DETAILS.FIRST_NAME,
  [NameType.Last]: FORM_IDS.DRIVER_DETAILS.LAST_NAME,
  [NameType.Full]: FORM_IDS.DRIVER_DETAILS.FULL_NAME
}

const Name: FC<IProps & ReadOnlyProps> = (props) => {
  const {
    type,
    readOnly,
    idPrefix,
    label = namesMapper[type],
    selectedDriverId,
    setSelectedDriverId
  } = props
  const { control, watch, setValue } = useFormContext()
  const { org, selectedPortal } = useStore((store) => store.user)

  const { organization_id = '' } = org || {}
  const siteId = selectedPortal?.id || ''

  const fieldId = withPrefix(idsMapper[type], idPrefix)

  const value = watch(fieldId)
  const [licensePlateNumber] = useDebounceValue(
    watch(FORM_IDS.POWER_UNIT.LPN),
    DEBOUNCE_TIME
  )

  const { loading, data } = useQuery(GetGateTransactionsQuery, {
    variables: {
      input: {
        organizationID: organization_id,
        siteID: siteId,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
        distinct: {
          field: DistinctFieldNames.DriverFirstNameDriverLastNameLpn
        },
        correlationOrder: {
          OrderDirection: OrderDirection.Desc
        },
        filter: {
          powerUnitLicensePlateNumber: {
            operator: StringOperator.Equals,
            value: licensePlateNumber
          },
          type: {
            operator: StringOperator.In,
            values: [DomainEventTypes.ManualOnsiteUpdate]
          }
        }
      }
    },
    skip: !licensePlateNumber || readOnly
  })

  const onSelectFromList = (option: GateTransaction | undefined) => {
    setSelectedDriverId(option?.id)

    if (!option) return

    const {
      driverFirstName = '',
      driverLastName = '',
      driverPhoneNumber = '',
      driverLicenseState = '',
      driverLicenseNumber = ''
    } = option.metadata

    setValue(FORM_IDS.DRIVER_DETAILS.FIRST_NAME, driverFirstName, {
      shouldValidate: true
    })
    setValue(FORM_IDS.DRIVER_DETAILS.LAST_NAME, driverLastName, {
      shouldValidate: true
    })
    setValue(FORM_IDS.DRIVER_DETAILS.PHONE, driverPhoneNumber, {
      shouldValidate: true
    })
    setValue(FORM_IDS.DRIVER_DETAILS.LICENSE_STATE, driverLicenseState, {
      shouldValidate: true
    })
    setValue(FORM_IDS.DRIVER_DETAILS.DRIVER_ID, driverLicenseNumber, {
      shouldValidate: true
    })
  }

  const customOptionLabel = useCallback(
    (option: GateTransaction) => (
      <DriverNameAutocompleteOption searchQuery={value} transaction={option} />
    ),
    [value]
  )

  if (readOnly) {
    return <ReadOnlyFormValue title={label} value={value} />
  }

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          required
          hideControls
          title="Previously Associated Drivers"
          label={label}
          name={field.name}
          inputValue={field.value}
          options={data?.listGateTransactions?.events || []}
          loading={loading}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          onInputChange={(e, newValue, reason) => {
            if (reason === 'input') {
              field.onChange(newValue)
              setSelectedDriverId(undefined)
            }
          }}
          getOptionLabel={(option: GateTransaction) =>
            `${option.metadata.driverFirstName} ${option.metadata.driverLastName}`
          }
          customOptionLabel={customOptionLabel}
          isOptionEqualToValue={(option: GateTransaction) =>
            option.id === selectedDriverId
          }
          onChange={onSelectFromList}
        />
      )}
    />
  )
}

export default Name
