import { StringOperator } from '@/__generated__/graphql'
import { useStore } from '@/store'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import {
  GetEnterpriseGateTransactionsQuery,
  GetGateTransactionsQuery
} from '../api/queries'
import { DomainTransactionTypes } from '../enums'

interface Props {
  query: any
  enterpriseMode?: boolean
  filters: any
}

const useGateTransactionRollups = ({
  query,
  enterpriseMode = false,
  filters
}: Props) => {
  const { selectedPortal } = useStore((store) => store.user)
  const site_id = selectedPortal?.id || ''

  const {
    transactionType: _ignore,
    ...remainingFiltersWithoutTransactionType
  } = filters

  const transactionTypes = filters?.transactionType?.values as
    | string[]
    | undefined

  const skipCheckIns =
    !!transactionTypes &&
    transactionTypes.length > 0 &&
    !transactionTypes.includes(DomainTransactionTypes.CheckIn)

  const skipCheckOuts =
    !!transactionTypes &&
    transactionTypes.length > 0 &&
    !transactionTypes.includes(DomainTransactionTypes.CheckOut)

  const modeQuery = enterpriseMode
    ? GetEnterpriseGateTransactionsQuery
    : GetGateTransactionsQuery

  const { loading: loadingCheckInData, data: checkInData } = useQuery(
    modeQuery,
    {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutTransactionType,
            transactionType: {
              operator: StringOperator.In,
              values: [DomainTransactionTypes.CheckIn]
            }
          }
        }
      },
      skip: skipCheckIns
    }
  )

  const { loading: loadingCheckOutData, data: checkOutData } = useQuery(
    modeQuery,
    {
      variables: {
        input: {
          siteID: enterpriseMode ? undefined : site_id,
          ...query,
          filter: {
            ...remainingFiltersWithoutTransactionType,
            transactionType: {
              operator: StringOperator.In,
              values: [DomainTransactionTypes.CheckOut]
            }
          }
        }
      },
      skip: skipCheckOuts
    }
  )

  const checkInDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (checkInData as any)?.listEnterpriseGateTransactions?.pagination
          .totalItemsCount || 0
      )
    }

    return checkInData?.listGateTransactions?.pagination.totalItemsCount || 0
  }, [checkInData, loadingCheckInData, enterpriseMode, filters])

  const checkOutDataCount = useMemo(() => {
    if (enterpriseMode) {
      return (
        (checkOutData as any)?.listEnterpriseGateTransactions?.pagination
          .totalItemsCount || 0
      )
    }

    return checkOutData?.listGateTransactions?.pagination.totalItemsCount || 0
  }, [checkOutData, loadingCheckOutData, enterpriseMode, filters])

  return {
    loadingCheckInData,
    checkInDataCount,
    loadingCheckOutData,
    checkOutDataCount
  }
}

export default useGateTransactionRollups
