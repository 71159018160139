interface LegendLineProps {
  lineColor?: string
  width?: number
  height?: number
  radius?: number
}

const LegendLine = ({
  lineColor,
  width = 16,
  height = 2,
  radius = 0
}: LegendLineProps) => {
  return (
    <svg width={width} height={height}>
      <rect
        fill={lineColor}
        width={width}
        height={height}
        rx={radius}
        ry={radius}
      />
    </svg>
  )
}

export default LegendLine
