import { matchPath, useLocation } from 'react-router-dom'

const usePathMatch = (urls: string | string[]) => {
  const location = useLocation()

  return Array.isArray(urls)
    ? urls.some((url) => !!matchPath(url, location.pathname))
    : !!matchPath(urls, location.pathname)
}

export default usePathMatch
