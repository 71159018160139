import clsx from 'clsx'
import { FC, isValidElement, ReactNode } from 'react'

import { Col, ProgressBar, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { orFallback } from '@/utils/helpers'

interface IProps {
  title: string
  value?: string | number | undefined | null | ReactNode
  loading?: boolean
  required?: boolean
  className?: string
  biggerGap?: boolean
}

const ReadOnlyFormValue: FC<IProps> = (props) => {
  const { title, value, biggerGap, required, className, loading } = props

  const ValueComponent = isValidElement(value) ? (
    value
  ) : (
    <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
      {orFallback(value as string)}
    </Text>
  )

  return (
    <Col
      gap={!value || biggerGap || loading ? 5 : 2}
      className={clsx('tw-w-full tw-self-stretch', className)}
    >
      <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
        {title}
        {required && <span className="tw-ml-3 color-red400">*</span>}
      </Text>

      {loading ? <ProgressBar /> : ValueComponent}
    </Col>
  )
}

export default ReadOnlyFormValue
