import { Axis } from '@visx/xychart'
import tickFormatter from './tickFormatter'

interface XAxisLabelsProps {
  color?: string
  numTicks?: number
  strokeWidth?: number
}

const XAxisLabels = ({
  color = '#6B7280',
  numTicks = 3,
  strokeWidth = 1.5
}: XAxisLabelsProps) => {
  return (
    <Axis
      orientation="bottom"
      stroke={color}
      strokeWidth={strokeWidth}
      tickStroke={color}
      numTicks={numTicks}
      labelProps={{
        fill: color
      }}
      tickLabelProps={() => ({
        fill: color
      })}
      tickFormat={(value) => tickFormatter(value)}
    />
  )
}

export default XAxisLabels
