import { GateTransaction } from '@/__generated__/graphql'
import React, { FC, useMemo } from 'react'
import {
  DriverDetailsFormSection,
  FormAside,
  ModalWithForm
} from '@/features/forms/components'
import { TwoColumnModalWrapper } from '@/components/molecules'
import { orFallback } from '@/utils/helpers'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormSectionsPresent } from '@/features/gate/hooks'
import {
  findGateBasedOnLaneId,
  GateTransactionSchema,
  GateTransactionSchemaType,
  getFormValuesFromGateTransaction
} from '@/features/gate/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { CheckInCargoAsset, CheckInPowerUnit } from '@/features/gate'
import { useStore } from '@/store'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { useGetPresignedUrlQuery } from '@/features/gate/api'
import transactionTypeToDirection from '@/utils/table/converters/transactionTypeToDirection'

interface Props extends IModalWithCloseFn {
  transaction: GateTransaction
}

const GateTransactionDetailsModal: FC<Props> = (props) => {
  const { transaction, closeModal } = props

  const { metadata, referenceId, id, transactionType, siteId } = transaction
  const { laneId } = metadata

  const { org, gates = [] } = useStore((store) => store.user)
  const { isSectionPresent } = useFormSectionsPresent(transaction)

  const orgId = org?.organization_id || ''

  const { data: imageUrl } = useGetPresignedUrlQuery(
    {
      org_id: orgId,
      site_id: siteId,
      event_id: id
    },
    { skip: !referenceId }
  )

  const formReturn = useForm<GateTransactionSchemaType>({
    resolver: yupResolver(GateTransactionSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: getFormValuesFromGateTransaction(transaction)
  })

  const gate = useMemo(
    () => findGateBasedOnLaneId(gates, laneId),
    [gates, laneId]
  )

  const laneDisplayName = useMemo(
    () => gate?.lanes?.find?.((lane) => lane.id === laneId)?.display_name,
    [gate, laneId]
  )

  const direction = transactionTypeToDirection(
    { transactionType },
    'transactionType'
  )

  return (
    <FormProvider {...formReturn}>
      <ModalWithForm
        title={`${direction} Details`}
        closeModal={closeModal}
        cancelButtonText="Close"
        isSectionPresent={isSectionPresent}
      >
        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!referenceId}
              image={imageUrl}
              gateName={orFallback(gate?.display_name)}
              laneName={orFallback(laneDisplayName)}
            />
          }
        >
          <form>
            {isSectionPresent.powerUnitAndDriverDetails && (
              <>
                <CheckInPowerUnit
                  readOnly
                  isPresent
                  siteId={siteId}
                  allowApiCalls={false}
                />

                <DriverDetailsFormSection readOnly />
              </>
            )}

            {isSectionPresent.cargoAssetDetails && (
              <CheckInCargoAsset isPresent readOnly />
            )}
          </form>
        </TwoColumnModalWrapper>
      </ModalWithForm>
    </FormProvider>
  )
}

export default GateTransactionDetailsModal
