export const ENTERPRISE_ROUTE_PREFIX = '/enterprise'

const ROUTES = {
  HOME: '/',
  PROFILE: '/profile',

  GATE: '/sites/:site_id/',

  QUEUES: '/sites/:site_id/queues',

  MANUAL_CHECK_IN: '/sites/:site_id/queues/check-in',
  CHECK_IN: '/sites/:site_id/queues/check-in/:event_and_lane_id',
  
  MANUAL_CHECK_OUT: '/sites/:site_id/queues/check-out',
  CHECK_OUT: '/sites/:site_id/queues/check-out/:event_and_lane_id',

  TRANSACTIONS: '/sites/:site_id/transactions',
  TRANSACTION: '/sites/:site_id/transactions/:transaction_id',

  DRIVERS: '/sites/:site_id/drivers',
  DRIVER: '/sites/:site_id/drivers/:driver_id',

  CARGO_ASSETS: '/sites/:site_id/cargo-assets',
  CARGO_ASSET: '/sites/:site_id/cargo-assets/:cargo_asset_id',

  ISR: '/sites/:site_id/isr',
  ISR_TRIP: '/sites/:site_id/isr/:trip_id',

  ENTERPRISE: ENTERPRISE_ROUTE_PREFIX,
  
  ENTERPRISE_USERS: `${ENTERPRISE_ROUTE_PREFIX}/users`,
  ENTERPRISE_INVITES: `${ENTERPRISE_ROUTE_PREFIX}/invites`,

  ENTERPRISE_TRANSACTIONS: `${ENTERPRISE_ROUTE_PREFIX}/transactions`,
  ENTERPRISE_TRANSACTION: `${ENTERPRISE_ROUTE_PREFIX}/transactions/:transaction_id`,

  ENTERPRISE_DRIVERS: `${ENTERPRISE_ROUTE_PREFIX}/drivers`,
  ENTERPRISE_DRIVER: `${ENTERPRISE_ROUTE_PREFIX}/drivers/:driver_id`,

  ENTERPRISE_CARGO_ASSETS: `${ENTERPRISE_ROUTE_PREFIX}/cargo-assets`,
  ENTERPRISE_CARGO_ASSET: `${ENTERPRISE_ROUTE_PREFIX}/cargo-assets/:cargo_asset_id`,

  ENTERPRISE_ISR: `${ENTERPRISE_ROUTE_PREFIX}/isr`,
  ENTERPRISE_ISR_TRIP: `${ENTERPRISE_ROUTE_PREFIX}/isr/:trip_id`
}

export default ROUTES
