import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Chip, Text } from '@/components/atoms'
import { TwoColumnModalWrapper } from '@/components/molecules'
import {
  DriverDetailsFormSection,
  FormSection,
  ModalWithForm
} from '@/features/forms/components'
import { CheckInPowerUnit } from '@/features/gate'
import { useGetPresignedUrlQuery } from '@/features/gate/api'
import {
  DomainEventSchema,
  DomainEventTypes,
  QueuePrefix
} from '@/features/gate/enums'
import { IsrFormAside } from '@/features/isr'
import { useUpdateISRRecordMutation } from '@/features/isr/api'
import {
  UpdateISRRecordRequestBody,
  UpdateISRRecordRequestParams
} from '@/features/isr/api/types'
import { ISRVisitSchema, ISRVisitSchemaType } from '@/features/isr/utils'
import { useSnackbar } from '@/features/snackbars-queue'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { orFallback, scrollToFirstFormError } from '@/utils/helpers'
import {
  convertTransactionToSnakeCase,
  emissionTypeByFuelMapper
} from '@/utils/mappers'
import { GateTransactionMetadata } from '@/features/gate/types'
import { IsrEventPair, ListIsrReportsQuery } from '@/__generated__/graphql'
import {
  AppointmentTypes,
  FuelTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'
import { findGateBasedOnLaneId } from '@/features/gate/utils'
import { ApolloQueryResult } from '@apollo/client'
import { DriverSectionTitleAppearance } from '@/features/forms/components/DriverDetailsFormSection/DriverDetailsFormSection'

import styles from './UpdateIsrReportModal.module.scss'

interface IProps extends IModalWithCloseFn {
  item: IsrEventPair
  refetch: () => Promise<ApolloQueryResult<ListIsrReportsQuery>>
}

const UpdateIsrReportModal: FC<IProps> = (props) => {
  const { item, closeModal, refetch } = props

  const { gates = [] } = useStore((store) => store.user)
  const { showErrorSnackbar } = useSnackbar()

  const {
    siteId,
    organizationId,

    ciEventId,
    ciEventUser,
    ciEventTime,
    ciCorrelationId,
    ciLaneDisplayName,
    ciMetadata,

    coEventId,
    coEventTime,
    coEventUser,
    coCorrelationId,
    coLaneDisplayName,
    coMetadata,

    powerUnitLicensePlateNumber,
    powerUnitLicensePlateState
  } = item

  const { mismatch } = coMetadata

  const [updateISRRecord, { isLoading }] = useUpdateISRRecordMutation()

  const { data: inboundImage } = useGetPresignedUrlQuery(
    {
      org_id: organizationId,
      site_id: siteId,
      event_id: ciEventId
    },
    { skip: !ciEventId }
  )
  const { data: outboundImage } = useGetPresignedUrlQuery(
    {
      org_id: organizationId,
      site_id: siteId,
      event_id: coEventId
    },
    { skip: !coEventId }
  )

  const formState = useForm<ISRVisitSchemaType>({
    resolver: yupResolver(ISRVisitSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: false,

    defaultValues: {
      appointment_type: ciMetadata.appointmentType as AppointmentTypes,
      power_unit_type: ciMetadata.powerUnitType as PowerUnitTypes,
      power_unit_owner_id: ciMetadata.powerUnitOwnerId,
      power_unit_license_plate_number: powerUnitLicensePlateNumber,
      power_unit_license_plate_state: powerUnitLicensePlateState,
      power_unit_carrier_usdot: ciMetadata.powerUnitCarrierUsdot,
      power_unit_carrier_name: ciMetadata.powerUnitCarrierName,
      power_unit_vin: ciMetadata.powerUnitVin,
      power_unit_weight_class: ciMetadata.powerUnitWeightClass as WeightClasses,
      power_unit_fuel_type: ciMetadata.powerUnitFuelType as FuelTypes,

      ci_driver_first_name: ciMetadata.driverFirstName,
      ci_driver_last_name: ciMetadata.driverLastName,
      ci_driver_license_number: ciMetadata.driverLicenseNumber,
      ci_driver_phone_number: ciMetadata.driverPhoneNumber,
      ci_driver_license_state: ciMetadata.driverLicenseState,

      co_driver_first_name: coMetadata.driverFirstName,
      co_driver_last_name: coMetadata.driverLastName,
      co_driver_license_number: coMetadata.driverLicenseNumber,
      co_driver_phone_number: coMetadata.driverPhoneNumber,
      co_driver_license_state: coMetadata.driverLicenseState
    }
  })

  const sameDriverForBothTransactions =
    ciMetadata.driverFirstName === coMetadata.driverFirstName &&
    ciMetadata.driverLastName === coMetadata.driverLastName &&
    ciMetadata.driverLicenseNumber === coMetadata.driverLicenseNumber &&
    ciMetadata.driverLicenseState === coMetadata.driverLicenseState

  const {
    handleSubmit,
    formState: { submitCount, errors }
  } = formState

  const { checkInGate, checkOutGate } = useMemo(
    () => ({
      checkInGate: findGateBasedOnLaneId(gates, ciMetadata.laneId),
      checkOutGate: findGateBasedOnLaneId(gates, coMetadata.laneId)
    }),
    [gates]
  )

  const onSubmit = async (formData: ISRVisitSchemaType) => {
    if (!organizationId || !siteId) return

    const gateId = checkInGate?.id || checkOutGate?.id

    if (!gateId) {
      showErrorSnackbar('Gate not found')
      return
    }

    const commonMetadata: Partial<GateTransactionMetadata> = {
      power_unit_type: formData.power_unit_type,
      power_unit_owner_id: formData.power_unit_owner_id || '',
      power_unit_license_plate_number: formData.power_unit_license_plate_number,
      power_unit_license_plate_state: formData.power_unit_license_plate_state,
      power_unit_carrier_usdot: formData.power_unit_carrier_usdot || '',
      power_unit_carrier_name: formData.power_unit_carrier_name || '',
      power_unit_vin: formData.power_unit_vin || '',
      power_unit_weight_class: formData.power_unit_weight_class,
      power_unit_fuel_type: formData.power_unit_fuel_type,
      power_unit_emission_type:
        emissionTypeByFuelMapper[formData.power_unit_fuel_type],
      account_name: (formData.account_name as string[]) || []
    }

    const checkInDriverDetails: Partial<GateTransactionMetadata> = {
      driver_first_name: formData.ci_driver_first_name,
      driver_last_name: formData.ci_driver_last_name,
      driver_license_number: formData.ci_driver_license_number,
      driver_phone_number: formData.ci_driver_phone_number || '',
      driver_license_state: formData.ci_driver_license_state
    }

    const checkOutDriverDetails: Partial<GateTransactionMetadata> =
      sameDriverForBothTransactions
        ? checkInDriverDetails
        : {
            driver_first_name: formData.co_driver_first_name,
            driver_last_name: formData.co_driver_last_name,
            driver_license_number: formData.co_driver_license_number,
            driver_phone_number: formData.co_driver_phone_number || '',
            driver_license_state: formData.co_driver_license_state
          }

    const params: UpdateISRRecordRequestParams = {
      org_id: organizationId,
      site_id: siteId,
      // Both gate_id and lane_id does nothing on API level here, but they are still required to be inside request
      gate_id: gateId,
      lane_id: ciMetadata.laneId
    }

    const body: UpdateISRRecordRequestBody = {
      license_plate_number: formData.power_unit_license_plate_number,
      license_plate_state: formData.power_unit_license_plate_state,
      records: [
        {
          correlation_id: ciCorrelationId,
          event_type: DomainEventTypes.ManualISRRecordUpdate,
          schema: DomainEventSchema.September2024,
          metadata: {
            ...convertTransactionToSnakeCase(ciMetadata),
            ...commonMetadata,
            ...checkInDriverDetails
          }
        },
        {
          correlation_id: coCorrelationId,
          event_type: DomainEventTypes.ManualISRRecordUpdate,
          schema: DomainEventSchema.September2024,
          metadata: {
            ...convertTransactionToSnakeCase(coMetadata),
            ...commonMetadata,
            ...checkOutDriverDetails
          }
        }
      ]
    }

    const response = await updateISRRecord({
      params,
      body
    })

    if (!response.error) {
      closeModal()
      refetch()
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  return (
    <FormProvider {...formState}>
      <ModalWithForm
        title="Visit Details"
        closeModal={closeModal}
        isLoading={isLoading}
        isSectionPresent={{
          powerUnitAndDriverDetails: true,
          cargoAssetDetails: false
        }}
        onSubmit={handleSubmit(onSubmit)}
        header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
        footerLeft={
          <Text
            type={TextTypes.TEXT_SM}
            color={Color.gray700}
            className={styles.footerMessage}
          >
            Changes will only be reflected on the ISR record.
          </Text>
        }
      >
        <TwoColumnModalWrapper
          leftSide={
            <IsrFormAside
              showLaneAndGate={gates.length > 1}
              checkIn={{
                img: inboundImage,
                date: ciEventTime,
                user: ciEventUser,
                lane: ciLaneDisplayName,
                gate: orFallback(checkInGate?.display_name)
              }}
              checkOut={{
                img: outboundImage,
                date: coEventTime,
                user: coEventUser,
                lane: coLaneDisplayName,
                gate: orFallback(checkOutGate?.display_name)
              }}
            />
          }
        >
          <form>
            <CheckInPowerUnit
              isPresent
              hideAppointmentType
              allowApiCalls={false}
              siteId={siteId}
            />
            {sameDriverForBothTransactions ? (
              <DriverDetailsFormSection idPrefix={QueuePrefix.CheckIn} />
            ) : (
              <FormSection title="Driver Details">
                <DriverDetailsFormSection
                  withoutWrapper
                  title="Check-In"
                  idPrefix={QueuePrefix.CheckIn}
                  titleAppearance={DriverSectionTitleAppearance.Secondary}
                />
                <DriverDetailsFormSection
                  withoutWrapper
                  title="Check-Out"
                  idPrefix={QueuePrefix.CheckOut}
                  titleAppearance={DriverSectionTitleAppearance.Secondary}
                />
              </FormSection>
            )}
          </form>
        </TwoColumnModalWrapper>
      </ModalWithForm>
    </FormProvider>
  )
}

export default UpdateIsrReportModal
