import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  Jwt,
  SitePortalPermissions,
  UpdatePortalsPayload,
  UserPortalsListItem,
  UserStore
} from '@/features/auth/types'
import { jwtDecode } from 'jwt-decode'
import { PortalTypes } from '@/types/enums/global'
import { GateWithLanes } from '@/features/gate/types'
import { LocalStorageKeys } from '@/types/enums/storage'
import { cloneDeep } from 'lodash'

const initialState: UserStore = {
  me: undefined,
  selectedPortal: undefined,
  gates: undefined,
  selectedGate: undefined,
  authToken: undefined,
  org: undefined,
  portals: []
}

export const userStore = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserDetails: (state, action: PayloadAction<string>) => {
      const { payload } = action

      const decodedJwt: Jwt = jwtDecode(payload)

      const {
        user_id,
        first_name,
        last_name,
        nickname,
        name,
        email,
        email_verified,
        picture,
        organization_id,
        organization_subdomain,
        organization_display_name,
        organization_name
      } = decodedJwt

      state.authToken = payload
      state.org = {
        organization_id,
        organization_name,
        organization_subdomain,
        organization_display_name
      }
      state.me = {
        user_id,
        first_name,
        last_name,
        nickname,
        name,
        email,
        email_verified,
        picture
      }
    },

    changeUserName: (
      state,
      action: PayloadAction<{
        first_name: string
        last_name: string
        name: string
      }>
    ) => {
      const { first_name, last_name, name } = action.payload

      if (state.me) {
        state.me = {
          ...state.me,
          first_name,
          last_name,
          name
        }
      }
    },

    updatePortals: (state, action: PayloadAction<UpdatePortalsPayload>) => {
      const { payload } = action
      const { sites, enterpriseEnabled, portalIdFromUrl } = payload

      const availablePortals: UserPortalsListItem[] = cloneDeep(sites)
        .sort((a, b) => a.display_name.localeCompare(b.display_name))
        .map((site) => ({
          id: site.id,
          name: site.display_name,
          type: PortalTypes.Site,
          permissions: undefined
        }))

      if (enterpriseEnabled) {
        availablePortals.push({
          id: PortalTypes.Enterprise,
          name: 'Enterprise',
          type: PortalTypes.Enterprise,
          permissions: {
            users: true
          }
        })
      }

      state.portals = availablePortals

      if (!availablePortals.length) {
        state.selectedPortal = undefined
        state.selectedGate = undefined
        state.gates = undefined

        return state
      }

      let selectedPortalEntity: UserPortalsListItem | undefined = undefined

      if (portalIdFromUrl) {
        selectedPortalEntity = availablePortals.find(
          (portal) => portal.id === portalIdFromUrl
        )
      }

      if (!selectedPortalEntity) {
        const portalIdFromLocalStorage = localStorage.getItem(
          LocalStorageKeys.LastSelectedPortal
        )

        selectedPortalEntity = availablePortals.find(
          (portal) => portal.id === portalIdFromLocalStorage
        )
      }

      // If the selected portal is not found, default to the first available portal
      state.selectedPortal = selectedPortalEntity || availablePortals[0]

      localStorage.setItem(
        LocalStorageKeys.LastSelectedPortal,
        state.selectedPortal.id
      )
    },

    selectGate: (state, action: PayloadAction<string>) => {
      const gateId = action.payload

      const newSelectedGate = state.gates?.find((gate) => gate.id === gateId)

      if (newSelectedGate) {
        state.selectedGate = newSelectedGate
        localStorage.setItem(
          LocalStorageKeys.LastSelectedGate,
          newSelectedGate.id
        )
      }
    },

    selectPortal: (
      state,
      action: PayloadAction<{ portalId: string; redirect: () => void }>
    ) => {
      const { portalId, redirect } = action.payload

      const newSelectedPortal = state.portals.find(
        (portal) => portal.id === portalId
      )

      if (newSelectedPortal) {
        const isEnterprise = newSelectedPortal.type === PortalTypes.Enterprise

        if (
          state.selectedPortal?.type !== newSelectedPortal.type ||
          isEnterprise
        ) {
          redirect()
        }

        state.gates = undefined
        state.selectedGate = undefined

        state.selectedPortal = isEnterprise
          ? newSelectedPortal
          : { ...newSelectedPortal, permissions: undefined }

        localStorage.setItem(
          LocalStorageKeys.LastSelectedPortal,
          newSelectedPortal.id
        )
      }
    },

    updateSitePermissionsAndGate: (
      state,
      action: PayloadAction<{
        portalId: string
        permissions: SitePortalPermissions
        gates: GateWithLanes[]
        selectedGate: GateWithLanes | undefined
      }>
    ) => {
      const { portalId, permissions, selectedGate, gates } = action.payload

      state.gates = gates
      state.selectedGate = selectedGate

      if (selectedGate) {
        localStorage.setItem(LocalStorageKeys.LastSelectedGate, selectedGate.id)
      }

      state.portals = state.portals.map((portal) =>
        portal.id === portalId && portal.type === PortalTypes.Site
          ? { ...portal, permissions }
          : portal
      )

      if (
        state.selectedPortal?.id === portalId &&
        state.selectedPortal?.type === PortalTypes.Site
      ) {
        state.selectedPortal = {
          ...state.selectedPortal,
          permissions
        }
      }
    },

    logoutUser: (state) => {
      state = initialState
    }
  }
})

export const userStoreReducer = userStore.reducer

export const {
  updateUserDetails,
  logoutUser,
  updatePortals,
  selectPortal,
  selectGate,
  updateSitePermissionsAndGate,
  changeUserName
} = userStore.actions
