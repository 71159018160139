import { useWindowSize } from 'usehooks-ts'

const BREAKPOINTS = {
  XL: 1200,
  LG: 1024,
  MD: 800,
  SM: 600,
  XS: 360
}

const useViewportSize = () => {
  const { width } = useWindowSize()

  const isExtraSmallView = width <= BREAKPOINTS.XS
  const isSmallView = width <= BREAKPOINTS.SM
  const isMediumView = width <= BREAKPOINTS.MD
  const isLargeView = width <= BREAKPOINTS.LG
  const isExtraLargeView = width <= BREAKPOINTS.XL

  return {
    isExtraSmallView,
    isSmallView,
    isMediumView,
    isLargeView,
    isExtraLargeView
  }
}

export default useViewportSize
