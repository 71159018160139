import React, { FC, PropsWithChildren, BaseSyntheticEvent } from 'react'

import { Button } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { ModalProps } from '@/components/organisms/Modal/Modal'
import { FormAnchorTabs } from '@/features/forms/components'
import { FormSectionType } from '@/features/forms/enums'
import { SectionPresent } from '@/features/gate/hooks/useFormSectionsPresent'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

import styles from './ModalWithForm.module.scss'

interface Props extends ModalProps {
  title: string
  isSectionPresent: SectionPresent
  onAddSection?: (section: FormSectionType) => void
  submitDisabled?: boolean
  buttonText?: string
  isLoading?: boolean
  onSubmit?: (e: BaseSyntheticEvent) => void | Promise<void>
}

const ModalWithForm: FC<PropsWithChildren<Props & IModalWithCloseFn>> = (
  props
) => {
  const {
    children,
    title,
    isSectionPresent,
    isLoading,
    onAddSection,
    buttonText = 'Save Changes',
    submitDisabled,
    onSubmit,

    ...restProps
  } = props

  return (
    <Modal
      {...restProps}
      formMode
      title={title}
      cancelButtonText="Cancel"
      placement="fullScreen"
      Navigation={
        <FormAnchorTabs
          onAdd={onAddSection}
          hasPowerUnitAndDriver={isSectionPresent.powerUnitAndDriverDetails}
          hasCargoAsset={isSectionPresent.cargoAssetDetails}
        />
      }
      footer={
        !!onSubmit && (
          <Button
            type="primary"
            action="submit"
            disabled={isLoading || submitDisabled}
            onClick={onSubmit}
            className={styles.actionButton}
          >
            {isLoading ? 'Loading...' : buttonText}
          </Button>
        )
      }
      bodyClassName={styles.modalWithForm}
    >
      {children}
    </Modal>
  )
}

export default ModalWithForm
