/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment gateTransactionMetadata on GateTransactionEventMetadata {\n    laneId,\n    transactionType\n          \n    appointmentType,\n    powerUnitCarrierMCNum,\n    powerUnitCarrierName,\n    powerUnitCarrierUsdot,\n    powerUnitEmissionType,\n    powerUnitFuelType,\n    powerUnitLicensePlateNumber,\n    powerUnitLicensePlateState,\n    powerUnitOwnerId,\n    powerUnitType,\n    powerUnitVin,\n    powerUnitWeightClass,\n    accountName,\n          \n    driverCompanyId,\n    driverFirstName,\n    driverLastName,\n    driverLicenseNumber,\n    driverLicenseState,\n    driverPhoneNumber,\n          \n    cargoAssetAssetType,\n    cargoAssetCarrierName,\n    cargoAssetLicensePlateNumber,\n    cargoAssetLicensePlateState,\n    cargoAssetOwnerId,\n    chassisId,\n    inspectionCompleted,\n    loadStatus,\n    mismatch,\n    sealMatchPW,\n    sealNumbers,\n    shipmentNumber,\n  }\n": types.GateTransactionMetadataFragmentDoc,
    "\n  fragment gateTransactionDetails on GateTransaction {\n    id,\n    correlationId,\n    createdBy,\n    createdDate,\n    eventTime,\n    organizationId,\n    referenceId,\n    siteId,\n    transactionType,\n    type,\n    laneDisplayName,\n\n    metadata {\n        ...gateTransactionMetadata\n    }\n  }\n": types.GateTransactionDetailsFragmentDoc,
    "\n  fragment paginationResponse on Pagination {\n    totalItemsCount\n    totalPages\n    currentPage\n  }\n": types.PaginationResponseFragmentDoc,
    "\n  query ListGateTransactions($input: ListGateTransactionEventsInput!) {\n    listGateTransactions(input: $input) {\n      events {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListGateTransactionsDocument,
    "\n  query ListEnterpriseGateTransactions($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseGateTransactions(input: $input) {\n      events {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListEnterpriseGateTransactionsDocument,
    "\n  query ListCargoAssetsOnsite($input: ListGateTransactionEventsInput!) {\n    listCargoAssetsOnSite(input: $input) {\n      assets {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListCargoAssetsOnsiteDocument,
    "\n  query ListEnterpriseCargoAssetsOnsite($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseCargoAssetsOnSites(input: $input) {\n      assets {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListEnterpriseCargoAssetsOnsiteDocument,
    "\n  query ListDriversOnsite($input: ListGateTransactionEventsInput!) {\n    listDriversOnSite(input: $input) {\n      drivers {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListDriversOnsiteDocument,
    "\n  query ListEnterpriseDriversOnsite($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseDriversOnSites(input: $input) {\n      drivers {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListEnterpriseDriversOnsiteDocument,
    "\n  fragment isrReportDetails on ISREventPair {\n    organizationId,\n    siteId,\n        \n    powerUnitLicensePlateNumber,\n    powerUnitLicensePlateState,\n        \n    ciEventId,\n    ciCorrelationId,\n    ciEventUser,\n    ciEventTime,\n    ciMetadata {\n        ...gateTransactionMetadata\n    },\n    ciLaneDisplayName,\n        \n    coEventId,\n    coCorrelationId,\n    coEventUser,\n    coEventTime,\n    coMetadata {\n        ...gateTransactionMetadata\n    },\n    coLaneDisplayName,\n        \n    dwellTime\n  }\n": types.IsrReportDetailsFragmentDoc,
    "\n  query ListIsrReports($input: ListISRRecordsInput!) {\n    listISRRecords(input: $input) {\n      ISRRecords {\n        ...isrReportDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListIsrReportsDocument,
    "\n  query ListEnterpriseIsrReports($input: ListEnterpriseISRRecordsInput!) {\n    listEnterpriseISRRecords(input: $input) {\n      ISRRecords {\n        ...isrReportDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n": types.ListEnterpriseIsrReportsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment gateTransactionMetadata on GateTransactionEventMetadata {\n    laneId,\n    transactionType\n          \n    appointmentType,\n    powerUnitCarrierMCNum,\n    powerUnitCarrierName,\n    powerUnitCarrierUsdot,\n    powerUnitEmissionType,\n    powerUnitFuelType,\n    powerUnitLicensePlateNumber,\n    powerUnitLicensePlateState,\n    powerUnitOwnerId,\n    powerUnitType,\n    powerUnitVin,\n    powerUnitWeightClass,\n    accountName,\n          \n    driverCompanyId,\n    driverFirstName,\n    driverLastName,\n    driverLicenseNumber,\n    driverLicenseState,\n    driverPhoneNumber,\n          \n    cargoAssetAssetType,\n    cargoAssetCarrierName,\n    cargoAssetLicensePlateNumber,\n    cargoAssetLicensePlateState,\n    cargoAssetOwnerId,\n    chassisId,\n    inspectionCompleted,\n    loadStatus,\n    mismatch,\n    sealMatchPW,\n    sealNumbers,\n    shipmentNumber,\n  }\n"): (typeof documents)["\n  fragment gateTransactionMetadata on GateTransactionEventMetadata {\n    laneId,\n    transactionType\n          \n    appointmentType,\n    powerUnitCarrierMCNum,\n    powerUnitCarrierName,\n    powerUnitCarrierUsdot,\n    powerUnitEmissionType,\n    powerUnitFuelType,\n    powerUnitLicensePlateNumber,\n    powerUnitLicensePlateState,\n    powerUnitOwnerId,\n    powerUnitType,\n    powerUnitVin,\n    powerUnitWeightClass,\n    accountName,\n          \n    driverCompanyId,\n    driverFirstName,\n    driverLastName,\n    driverLicenseNumber,\n    driverLicenseState,\n    driverPhoneNumber,\n          \n    cargoAssetAssetType,\n    cargoAssetCarrierName,\n    cargoAssetLicensePlateNumber,\n    cargoAssetLicensePlateState,\n    cargoAssetOwnerId,\n    chassisId,\n    inspectionCompleted,\n    loadStatus,\n    mismatch,\n    sealMatchPW,\n    sealNumbers,\n    shipmentNumber,\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment gateTransactionDetails on GateTransaction {\n    id,\n    correlationId,\n    createdBy,\n    createdDate,\n    eventTime,\n    organizationId,\n    referenceId,\n    siteId,\n    transactionType,\n    type,\n    laneDisplayName,\n\n    metadata {\n        ...gateTransactionMetadata\n    }\n  }\n"): (typeof documents)["\n  fragment gateTransactionDetails on GateTransaction {\n    id,\n    correlationId,\n    createdBy,\n    createdDate,\n    eventTime,\n    organizationId,\n    referenceId,\n    siteId,\n    transactionType,\n    type,\n    laneDisplayName,\n\n    metadata {\n        ...gateTransactionMetadata\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment paginationResponse on Pagination {\n    totalItemsCount\n    totalPages\n    currentPage\n  }\n"): (typeof documents)["\n  fragment paginationResponse on Pagination {\n    totalItemsCount\n    totalPages\n    currentPage\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListGateTransactions($input: ListGateTransactionEventsInput!) {\n    listGateTransactions(input: $input) {\n      events {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListGateTransactions($input: ListGateTransactionEventsInput!) {\n    listGateTransactions(input: $input) {\n      events {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListEnterpriseGateTransactions($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseGateTransactions(input: $input) {\n      events {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListEnterpriseGateTransactions($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseGateTransactions(input: $input) {\n      events {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListCargoAssetsOnsite($input: ListGateTransactionEventsInput!) {\n    listCargoAssetsOnSite(input: $input) {\n      assets {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListCargoAssetsOnsite($input: ListGateTransactionEventsInput!) {\n    listCargoAssetsOnSite(input: $input) {\n      assets {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListEnterpriseCargoAssetsOnsite($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseCargoAssetsOnSites(input: $input) {\n      assets {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListEnterpriseCargoAssetsOnsite($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseCargoAssetsOnSites(input: $input) {\n      assets {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListDriversOnsite($input: ListGateTransactionEventsInput!) {\n    listDriversOnSite(input: $input) {\n      drivers {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListDriversOnsite($input: ListGateTransactionEventsInput!) {\n    listDriversOnSite(input: $input) {\n      drivers {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListEnterpriseDriversOnsite($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseDriversOnSites(input: $input) {\n      drivers {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListEnterpriseDriversOnsite($input: ListEnterpriseGateTransactionEventsInput!) {\n    listEnterpriseDriversOnSites(input: $input) {\n      drivers {\n        ...gateTransactionDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment isrReportDetails on ISREventPair {\n    organizationId,\n    siteId,\n        \n    powerUnitLicensePlateNumber,\n    powerUnitLicensePlateState,\n        \n    ciEventId,\n    ciCorrelationId,\n    ciEventUser,\n    ciEventTime,\n    ciMetadata {\n        ...gateTransactionMetadata\n    },\n    ciLaneDisplayName,\n        \n    coEventId,\n    coCorrelationId,\n    coEventUser,\n    coEventTime,\n    coMetadata {\n        ...gateTransactionMetadata\n    },\n    coLaneDisplayName,\n        \n    dwellTime\n  }\n"): (typeof documents)["\n  fragment isrReportDetails on ISREventPair {\n    organizationId,\n    siteId,\n        \n    powerUnitLicensePlateNumber,\n    powerUnitLicensePlateState,\n        \n    ciEventId,\n    ciCorrelationId,\n    ciEventUser,\n    ciEventTime,\n    ciMetadata {\n        ...gateTransactionMetadata\n    },\n    ciLaneDisplayName,\n        \n    coEventId,\n    coCorrelationId,\n    coEventUser,\n    coEventTime,\n    coMetadata {\n        ...gateTransactionMetadata\n    },\n    coLaneDisplayName,\n        \n    dwellTime\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListIsrReports($input: ListISRRecordsInput!) {\n    listISRRecords(input: $input) {\n      ISRRecords {\n        ...isrReportDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListIsrReports($input: ListISRRecordsInput!) {\n    listISRRecords(input: $input) {\n      ISRRecords {\n        ...isrReportDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ListEnterpriseIsrReports($input: ListEnterpriseISRRecordsInput!) {\n    listEnterpriseISRRecords(input: $input) {\n      ISRRecords {\n        ...isrReportDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListEnterpriseIsrReports($input: ListEnterpriseISRRecordsInput!) {\n    listEnterpriseISRRecords(input: $input) {\n      ISRRecords {\n        ...isrReportDetails\n      }\n\n      pagination {\n        ...paginationResponse\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;