import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import { MAX_LENGTHS, MIN_LENGTHS } from '@/features/forms/constants'
import { FormLengthErrorType } from '@/features/forms/enums'
import {
  lengthErrorMessage,
  powerUnitValidations
} from '@/features/forms/utils'
import { isNotVisitor } from '@/features/gate/utils'
import {
  AppointmentTypes,
  FuelTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

const driverFields = {
  firstName: yup
    .string()
    .max(MAX_LENGTHS.DRIVER_NAME, lengthErrorMessage(MAX_LENGTHS.DRIVER_NAME))
    .required('First Name is required.'),

  lastName: yup
    .string()
    .max(MAX_LENGTHS.DRIVER_NAME, lengthErrorMessage(MAX_LENGTHS.DRIVER_NAME))
    .required('Last Name is required.'),

  licenseNumber: yup.string().when(powerUnitValidations.isNotVisitor.keys, {
    is: powerUnitValidations.isNotVisitor.validate,
    then: (schema) =>
      schema
        .max(
          MAX_LENGTHS.DRIVER_LICENSE_NUMBER,
          lengthErrorMessage(MAX_LENGTHS.DRIVER_LICENSE_NUMBER)
        )
        .required('Driver License is required.'),
    otherwise: (schema) => schema
  }),

  phoneNumber: yup
    .string()
    .test('format', 'Not valid phone number.', (val) =>
      val
        ? matchIsValidTel(val, {
            onlyCountries: ['US', 'MX', 'CA']
          })
        : true
    )
    .notRequired(),

  licenseState: yup.string().when(powerUnitValidations.isNotVisitor.keys, {
    is: powerUnitValidations.isNotVisitor.validate,
    then: (schema) => schema.required('State is required.'),
    otherwise: (schema) => schema
  })
}

export const ISRVisitSchema = yup.object({
  // Power Unit Section
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes)),
  power_unit_owner_id: yup
    .string()
    .max(MAX_LENGTHS.OWNER_ID, lengthErrorMessage(MAX_LENGTHS.OWNER_ID)),
  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes))
    .required('Type is required.'),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(MIN_LENGTHS.LPN, 'LPN is required.')
    .max(MAX_LENGTHS.LPN, lengthErrorMessage(MAX_LENGTHS.LPN))
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string().required('State is required.'),
  power_unit_carrier_name: yup.string().when('appointment_type', {
    is: isNotVisitor,
    then: (schema) => schema.required('Carrier is required.'),
    otherwise: (schema) => schema
  }),
  power_unit_carrier_usdot: yup.string().when('appointment_type', {
    is: isNotVisitor,
    then: (schema) =>
      schema
        .test(
          'len',
          lengthErrorMessage(MIN_LENGTHS.USDOT, FormLengthErrorType.Min),
          (val) => !!val && val?.toString()?.length >= MIN_LENGTHS.USDOT
        )
        .test(
          'len',
          lengthErrorMessage(MAX_LENGTHS.USDOT),
          (val) => !!val && val?.toString()?.length <= MAX_LENGTHS.USDOT
        )
        .required('US DOT # is required.'),
    otherwise: (schema) => schema
  }),
  power_unit_vin: yup
    .string()
    .max(MAX_LENGTHS.VIN, lengthErrorMessage(MAX_LENGTHS.VIN)),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses), 'Class is required.')
    .required('Class is required.'),
  power_unit_fuel_type: yup
    .mixed<FuelTypes>()
    .oneOf(Object.values(FuelTypes), 'Fuel Type is required.')
    .required('Fuel Type is required.'),
  account_name: yup.array().of(yup.string()),

  // Check-In driver details
  ci_driver_first_name: driverFields.firstName,
  ci_driver_last_name: driverFields.lastName,
  ci_driver_license_number: driverFields.licenseNumber,
  ci_driver_phone_number: driverFields.phoneNumber,
  ci_driver_license_state: driverFields.licenseState,

  // Check-out driver details
  co_driver_first_name: driverFields.firstName,
  co_driver_last_name: driverFields.lastName,
  co_driver_license_number: driverFields.licenseNumber,
  co_driver_phone_number: driverFields.phoneNumber,
  co_driver_license_state: driverFields.licenseState
})

export type ISRVisitSchemaType = yup.InferType<typeof ISRVisitSchema>
