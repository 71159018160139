import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Input, ReadOnlyFormValue } from '@/components/atoms'
import { FORM_IDS } from '@/features/forms/constants'
import { ReadOnlyProps } from '@/types/interfaces/ui'
import { withPrefix } from '@/utils/helpers'

interface Props extends ReadOnlyProps {
  idPrefix?: string
}

const Phone: FC<Props> = (props) => {
  const { readOnly, label = 'Driver Phone', idPrefix } = props
  const { control, watch } = useFormContext()

  const fieldId = withPrefix(FORM_IDS.DRIVER_DETAILS.PHONE, idPrefix)
  const driverPhone = watch(fieldId)

  if (readOnly) return <ReadOnlyFormValue title={label} value={driverPhone} />

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          fullWidth
          type="phone"
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}

export default Phone
