import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { Fade } from '@mui/material'
import clsx from 'clsx'
import { FC, KeyboardEvent, MouseEvent, useState } from 'react'

import { Avatar, Row, Text } from '@/components/atoms'
import { CollapsedPortalPicker } from '@/components/organisms/Navigation/components'
import { selectPortal } from '@/features/auth/store'
import { useNavigator } from '@/router'
import { useDispatch, useStore } from '@/store'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { onEnterPressed } from '@/utils/helpers'

import styles from './PortalsDropdownList.module.scss'
import useNavigateHome from '@/hooks/useNavigateHome'

interface IProps {
  collapsed: boolean
}

const PortalsDropdownList: FC<IProps> = (props) => {
  const { collapsed } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const dispatch = useDispatch()
  const navigate = useNavigator()
  const navigateHome = useNavigateHome()

  const { selectedPortal } = useStore((store) => store.user)

  const isEnterprise = selectedPortal?.type === PortalTypes.Enterprise

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    onEnterPressed(event, () => setAnchorEl(event.currentTarget as HTMLElement))
  }

  const onOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const onClose = () => setAnchorEl(null)

  const onPortalSelect = (id: string) => {
    dispatch(
      selectPortal({
        portalId: id,
        redirect: navigate.toHome
      })
    )

    navigateHome(id)
    onClose()
  }

  if (!selectedPortal?.id) return null

  return (
    <>
      <Row
        clickable
        gap={8}
        tabIndex={0}
        items="center"
        className={clsx(styles.portalValue, collapsed && styles.collapsed)}
        onKeyDown={handleKeyPress}
        onClick={onOpen}
      >
        <Avatar
          size={collapsed ? 'xl' : 'md'}
          rectangle
          name={selectedPortal.name}
          color={isEnterprise ? Color.purple400 : Color.blue300}
          textColor={Color.gray0}
          img={
            isEnterprise && (
              <BusinessOutlinedIcon
                className={clsx(styles.enterpriseSvg, collapsed && styles.big)}
              />
            )
          }
        />

        <Fade in={!collapsed} timeout={600} appear={false}>
          <div>
            <Text
              type={TextTypes.TEXT_SM}
              color={Color.gray300}
              weight={FontWeight.SEMIBOLD}
              className="tw-whitespace-nowrap"
            >
              {selectedPortal.name}
            </Text>
          </div>
        </Fade>
      </Row>

      <CollapsedPortalPicker
        onClose={onClose}
        anchorEl={anchorEl}
        onItemClick={onPortalSelect}
      />
    </>
  )
}

export default PortalsDropdownList
